import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { axiosInstanceV1, axiosInstanceV2 } from "network/apis";
import {
  findDefaultDate,
  findDefaultSlate,
  roundToTwoDecimals,
} from "utils/Shared";
import { buildLineup } from "store/buildLineup";
import { excludePlayer, unExcludePlayer } from "store/excludePlayer";
import { lockPlayer, unlockPlayer } from "store/lockPlayer";
import { saveLineupFunc, unSaveLineupFunc } from "store/savedLineups";
import { updateSortedColumnFunc } from "store/updateSortedColumnFunc";
import numeral from "numeral";
import { selectAllPlayers } from "store/playersSlice";
import { selectActiveMySetting } from "store/mySettingsSlice";

const slatesAdapter = createEntityAdapter();

export const initialState = slatesAdapter.getInitialState({
  disabledDates: [],
  error: null,
  excluded: {},
  excludeError: null,
  excludeStatus: "idle",
  fetchBuildsStatus: "idle",
  fetchDisabledDatesStatus: "idle",
  fetchGamesStatus: "idle",
  fetchMyDataStatus: "idle",
  fetchSavedLineupsStatus: "idle",
  fetchSlatesStatus: "idle",
  fetchSortedColumnStatus: "idle",
  fetchContestsStatus: "idle",
  saveLineupStatus: "idle",
  unSaveLineupStatus: "idle",
  uploadLateSwapStatus: "idle",
  commonSettingsStatus: "idle",
  backtestingValue: 0,
  games: [],
  isInitialFetch: true,
  isLateSwap: false,
  lateSwapFileName: null,
  lateSwapData: {},
  lateSwapError: null,
  lineups: [],
  locked: {},
  lockError: null,
  lockStatus: "idle",
  messageFromWS: [],
  myPFPs: {},
  myRands: {},
  pastBuilds: [],
  processorWebsocketMessages: {},
  previewLineup: [],
  previewStatus: "idle",
  progress: 0,
  savedLineups: [],
  slateID: null,
  sortedColumn: [],
  sortLineupBy: "pfp",
  teamStacksStatistic: {},
  theDate: null,
  updateMyDataStatus: null,
  updateSortedColumnStatus: null,
  notAvailableGamesSlate: [],
  contests: [],
  // common settings
  po_threshold: 10,
  pfp_threshold: 2,
  optimize_for_speed: true,
  optimize_for_speed_value: 10,
  late_swap_rebuild_times_with_random: 0,
  players_per_page: 20,
  rebuild_with_default_settings_on_crash: true,
  use_old_solver: false,
  max_time_in_seconds: 300,
  show_vegas_changes: false,

  wsProcessorError: false,
});

export const fetchBuilds = createAsyncThunk(
  "slates/fetchBuilds",
  async ({ sports, slateID }) => {
    const response = await axiosInstanceV2.get(`/${sports}/builds/`, {
      params: { slate_id: slateID },
    });
    return response.data;
  },
);

export const fetchSavedLineups = createAsyncThunk(
  "slates/fetchSavedLineups",
  async ({ sports, slateID }) => {
    const response = await axiosInstanceV1.get(`/${sports}/my-favorite/`, {
      params: { slate_id: slateID },
    });
    return response.data;
  },
);

export const fetchSortedColumn = createAsyncThunk(
  "slates/sortedColumn",
  async ({ sports }) => {
    const response = await axiosInstanceV2.get(`/core/sorted_column/columns/`, {
      params: { sport: sports },
    });
    return response.data;
  },
);

export const fetchDisabledDates = createAsyncThunk(
  "slates/disabledDates",
  async ({ sports, site }) => {
    const localDate = new Date();
    let offset = localDate.getTimezoneOffset();
    const response = await axiosInstanceV2.get(
      `/${sports}/slates/disabled_dates/`,
      { params: { site, offset } },
    );
    return response.data;
  },
);

export const fetchSlates = createAsyncThunk(
  "slates/fetchSlates",
  async ({ sports, site, theDate }) => {
    const localDate = new Date();
    const offset = localDate.getTimezoneOffset();
    const date = new Date(theDate);
    const dateAfter = new Date(date.getTime() + offset * 60000);
    const dateBefore = new Date(date.getTime() + (1440 + offset) * 60000);

    const response = await axiosInstanceV2.get(`/${sports}/slates/`, {
      params: {
        site,
        day_after: dateAfter.toJSON().replace(".000Z", "+00:00"),
        day_before: dateBefore.toJSON().replace(".000Z", "+00:00"),
      },
    });
    return response.data;
  },
);

export const fetchGames = createAsyncThunk(
  "slates/fetchGames",
  async ({ sports, slateID }) => {
    const response = await axiosInstanceV2.get(`/${sports}/games/`, {
      params: { slate_id: slateID },
    });
    return response.data;
  },
);

export const fetchMyData = createAsyncThunk(
  "slates/fetchMyData",
  async ({ slateID }) => {
    const response = await axiosInstanceV1.get("/misc/uploaded-player-data/", {
      params: { slate_id: slateID },
    });
    return response.data;
  },
);

export const uploadLateSwap = createAsyncThunk(
  "slates/uploadLateSwap",
  async ({ slate_id, league, site, csv_file }) => {
    let formData = new FormData();
    formData.append("csv_file", csv_file);
    formData.append("slate_id", slate_id);
    formData.append("league", league);
    formData.append("site", site);

    const response = await axiosInstanceV1.post("/misc/late_swap/", formData);
    return response.data;
  },
);

export const fetchContests = createAsyncThunk(
  "slates/fetchContests",
  async ({ sports, slateID }) => {
    const response = await axiosInstanceV1.get(`/misc/contests/`, {
      params: {
        league: sports,
        slate_id: slateID,
      },
    });
    return response.data;
  },
);

export const exclude = createAsyncThunk("slates/exclude", excludePlayer);

export const lock = createAsyncThunk("slates/lock", lockPlayer);

export const build = createAsyncThunk("slates/build", buildLineup);

export const saveLineup = createAsyncThunk("slates/saveLineup", saveLineupFunc);

export const unSaveLineup = createAsyncThunk(
  "slates/unSaveLineup",
  unSaveLineupFunc,
);

export const unlock = createAsyncThunk("slates/unlock", unlockPlayer);

export const updateSortedColumn = createAsyncThunk(
  "slates/updateSortedColumn",
  updateSortedColumnFunc,
);

export const unExclude = createAsyncThunk("slates/unExclude", unExcludePlayer);

let cachedRosters = [];

// Throttling the dispatch rate
let nextDispatchTime = 0;

export function addRosters(state, isFinish = false) {
  // Throttles the dispatch to once per second
  const timeNow = Date.now();
  if ((timeNow < nextDispatchTime && !isFinish) || !cachedRosters) return;

  nextDispatchTime = timeNow + 1000; // 1000ms = 1s
  const currentRosterIds = state.lineups.map((roster) => roster.id);
  state.lineups = [
    ...state.lineups,
    ...cachedRosters.filter((roster) => !currentRosterIds.includes(roster.id)),
  ];

  // Clear the cachedRosters after dispatching the action.
  cachedRosters = [];
}

export const buildFulfilled = (state, action) => {
  const data = JSON.parse(action.payload.message);

  if (state.progress === 999 || state.previewStatus === "succeeded") {
    try {
      const data = JSON.parse(action.payload.message);
      state.isLateSwap = data?.["is_late_swap"];
      addRosters(state, true);
    } catch (e) {
      console.error(e);
    }
    return;
  }

  if (data.progress) {
    if (data.progress === 1) {
      state.progress = 0.99;
    } else {
      state.progress = data.progress;
    }
  }
  if (data.roster) {
    if (!state.lineups.map((l) => l.id).includes(data.roster.id)) {
      cachedRosters.push(data.roster);
      addRosters(state);
    }
  }
  if (data.stacks) {
    state.teamStacksStatistic = data.stacks;
  }
  if (data.lineups) {
    const savedLineupsIds = state.lineups.map((lineup) => lineup.id);
    state.lineups = [
      ...state.lineups,
      ...data.lineups.filter((lineup) => !savedLineupsIds.includes(lineup.id)),
    ];
    addRosters(state, true);
  }
  if (data.done) {
    state.progress = 1;
    state.previewStatus = "succeeded";
    state.isLateSwap = data?.["is_late_swap"];
    addRosters(state, true);
  }
};

export const fetchCommonSettings = createAsyncThunk(
  "slates/fetchCommonSettings",
  // eslint-disable-next-line no-empty-pattern
  async ({}, {}) => {
    const response = await axiosInstanceV2.get(`core/common_setting/retrieve/`);
    return response.data;
  },
);

export const updateCommonSettings = createAsyncThunk(
  "slates/updateCommonSettings",
  // eslint-disable-next-line no-empty-pattern
  async ({ data }, {}) => {
    const response = await axiosInstanceV2.patch(
      `core/common_setting/patch/`,
      data,
    );
    return response.data;
  },
);

export const receiveProcessorWebSocketMessageFn = (state, action) => {
  const data = JSON.parse(action.payload.message);

  if (!Array.isArray(data)) {
    return;
  }

  for (const datum of data) {
    processWsMessage(datum, state);
  }
};

function processWsMessage(datum, state) {
  if (datum.hasOwnProperty("name")) {
    processWsMessagePlayerItem(datum, state);
  } else if (datum.hasOwnProperty("iwp")) {
    if (state.show_vegas_changes) {
      processWsMessageVegasItem(datum, state);
    }
  }
}

export function processWsMessageVegasItem(datum, state) {
  const team = datum["team"];
  const games = [...state.games];
  let gameFound = false;
  for (let game of games) {
    if (game.team_home === team) {
      game.team_home_vegas = datum;
      gameFound = true;
      break;
    }
    if (game.team_away === team) {
      game.team_away_vegas = datum;
      gameFound = true;
      break;
    }
  }
  if (gameFound) {
    const displayMessage = `${team} vegas was updated ITT: ${datum["vegas_team_total"]}, IWP: ${datum["iwp"]}`;
    showMessageFromWs(displayMessage, state);
    state.games = games;
  }
}

function processWsMessagePlayerItem(datum, state) {
  const name = datum["name"];
  let dataType = "";
  if (datum.hasOwnProperty("pfp")) {
    dataType = "pfp";
  }
  if (datum.hasOwnProperty("po")) {
    dataType = "po";
  }
  if (datum.hasOwnProperty("starting_position")) {
    dataType = "starting_position";
  }
  if (datum.hasOwnProperty("line")) {
    dataType = "line";
  }
  if (datum.hasOwnProperty("pp_line")) {
    dataType = "pp_line";
  }
  if (datum.hasOwnProperty("batting_order")) {
    dataType = "batting_order";
  }

  if (!state.processorWebsocketMessages.hasOwnProperty(name)) {
    state.processorWebsocketMessages[name] = {};
  }
  state.processorWebsocketMessages[name][dataType] = datum;
  const { pfp_threshold, po_threshold } = state;

  let displayMessage = "";

  if (
    dataType === "pfp" &&
    pfp_threshold <=
      Math.abs(parseFloat(datum.pfp) - parseFloat(datum.last_pfp))
  ) {
    displayMessage = `${datum.name} ${dataType.toUpperCase()} ${numeral(
      datum.last_pfp,
    ).format("0,0.00")} -> ${numeral(datum.pfp).format("0,0.00")}`;
  } else if (
    dataType === "po" &&
    po_threshold <= Math.abs(parseFloat(datum.po) - parseFloat(datum.last_po))
  ) {
    displayMessage = `${datum.name} ${dataType.toUpperCase()} ${numeral(
      datum.last_po,
    ).format("0,0.00")} -> ${numeral(datum.po).format("0,0.00")}`;
  } else if (dataType === "starting_position") {
    if (!datum.starting_position) {
      displayMessage = `${datum.name} was removed from lineup`;
    } else {
      displayMessage = `${datum.name} was added to starting lineup`;
    }
  } else if (dataType === "line") {
    if (!datum.line) {
      displayMessage = `${datum.name} was removed from lineup`;
    } else {
      displayMessage = `${datum.name} was moved to ${datum.line} line`;
    }
  } else if (dataType === "pp_line") {
    if (!datum.pp_line && !datum.current_pp_line) {
      displayMessage = "";
    } else if (datum.pp_line === datum.current_pp_line) {
      displayMessage = "";
    } else if (!datum.pp_line) {
      displayMessage = `${datum.name} was removed from pp_line`;
    } else {
      displayMessage = `${datum.name} was moved to ${datum.pp_line} line`;
    }
  } else if (dataType === "batting_order") {
    if (!datum.batting_order || datum.batting_order === "NIS") {
      displayMessage = `${datum.name} was removed from lineup`;
    } else {
      displayMessage = `${datum.name} will start on ${datum.batting_order}`;
    }
  } else {
    return;
  }
  showMessageFromWs(displayMessage, state);
}

export function showMessageFromWs(displayMessage, state) {
  const messageData = { message: displayMessage, isShowed: false };
  if (displayMessage && state.messageFromWS.length >= 10) {
    state.messageFromWS.shift();
  }
  if (displayMessage) {
    state.messageFromWS.push(messageData);
  }
}

const slatesSlice = createSlice({
  name: "slates",
  initialState,
  reducers: {
    resetGames(state) {
      state.games = [];
    },
    resetExcludeErrorAndStatus(state) {
      state.excludeStatus = "idle";
      state.excludeError = null;
    },
    resetLockErrorAndStatus(state) {
      state.lockStatus = "idle";
      state.lockError = null;
    },
    setIsInitialFetch(state) {
      state.isInitialFetch = false;
    },
    setSlateID(state, action) {
      state.slateID = action.payload;
    },
    setTheDate(state, action) {
      state.theDate = action.payload;
    },
    setBacktestingValue(state, action) {
      state.backtestingValue = action.payload;
    },
    setLateSwapData(state, action) {
      state.lateSwapData[state.slateID] = action.payload;
    },
    setLoadingForPreviewStatus(state) {
      state.previewStatus = "loading";
    },
    setSortLineupBy(state, action) {
      state.sortLineupBy = action.payload;
    },
    resetIsLateSwap(state) {
      state.isLateSwap = false;
    },
    resetSlates(state) {
      slatesAdapter.removeAll(state);
      state.disabledDates = [];
      state.error = null;
      state.games = [];
      state.isInitialFetch = true;
      state.previewLineup = [];
      state.lineups = [];
      state.progress = 0;
      state.slateID = null;
      state.sortedColumn = [];
      state.theDate = null;
      state.sortLineupBy = "pfp";
      state.contests = [];
    },
    resetPreviewLineup(state) {
      state.previewLineup = [];
    },
    stopBuild(state) {
      state.progress = 999;
      state.previewStatus = "succeeded";
    },
    resetBuild(state) {
      state.lineups = [];
      state.progress = 0;
    },
    resetPastBuilds(state) {
      state.pastBuilds = [];
      state.fetchBuildsStatus = "idle";
    },
    resetSavedLineups(state) {
      state.savedLineups = [];
      state.fetchSavedLineupsStatus = "idle";
    },
    markWsMessagesAsShowed(state) {
      state.messageFromWS = state.messageFromWS.map((messageData) => ({
        ...messageData,
        isShowed: true,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuilds.pending, (state) => {
        state.fetchBuildsStatus = "loading";
      })
      .addCase(fetchBuilds.fulfilled, (state, action) => {
        state.fetchBuildsStatus = "succeeded";
        state.pastBuilds = action.payload;
      })
      .addCase(fetchBuilds.rejected, (state, action) => {
        state.fetchBuildsStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSavedLineups.pending, (state) => {
        state.fetchSavedLineupsStatus = "loading";
      })
      .addCase(fetchSavedLineups.fulfilled, (state, action) => {
        state.fetchSavedLineupsStatus = "succeeded";
        state.savedLineups = action.payload;
      })
      .addCase(fetchSavedLineups.rejected, (state, action) => {
        state.fetchSavedLineupsStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSortedColumn.pending, (state) => {
        state.fetchSortedColumnStatus = "loading";
      })
      .addCase(fetchSortedColumn.fulfilled, (state, action) => {
        state.fetchSortedColumnStatus = "succeeded";
        state.sortedColumn = action.payload;
      })
      .addCase(fetchSortedColumn.rejected, (state, action) => {
        state.fetchSortedColumnStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchDisabledDates.pending, (state) => {
        state.fetchDisabledDatesStatus = "loading";
      })
      .addCase(fetchDisabledDates.fulfilled, (state, action) => {
        state.fetchDisabledDatesStatus = "succeeded";
        const disabledDates = action.payload;
        state.disabledDates = disabledDates;
        state.theDate = findDefaultDate(disabledDates);
      })
      .addCase(fetchDisabledDates.rejected, (state, action) => {
        state.fetchDisabledDatesStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSlates.pending, (state) => {
        state.fetchSlatesStatus = "loading";
      })
      .addCase(fetchSlates.fulfilled, (state, action) => {
        state.fetchSlatesStatus = "succeeded";
        const slates = action.payload;
        slatesAdapter.setAll(state, slates);

        state.slateID = findDefaultSlate(slates);
      })
      .addCase(fetchSlates.rejected, (state, action) => {
        state.fetchSlatesStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchGames.pending, (state) => {
        state.fetchGamesStatus = "loading";
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        state.fetchGamesStatus = "succeeded";
        state.games = action.payload;
        if (state.games && state.games.length === 0) {
          state.notAvailableGamesSlate.push(state.slateID);
        }
      })
      .addCase(fetchGames.rejected, (state, action) => {
        state.fetchGamesStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchMyData.pending, (state) => {
        state.fetchMyDataStatus = "loading";
      })
      .addCase(fetchMyData.fulfilled, (state, action) => {
        state.fetchMyDataStatus = "succeeded";
        state.myData = action.payload;
      })
      .addCase(fetchMyData.rejected, (state, action) => {
        state.fetchMyDataStatus = "failed";
        state.error = action.payload;
      })
      .addCase(updateSortedColumn.pending, (state) => {
        state.updateSortedColumnStatus = "loading";
      })
      .addCase(updateSortedColumn.fulfilled, (state, action) => {
        state.updateSortedColumnStatus = "succeeded";
        const { datum } = action.payload;
        state.sortedColumn = datum;
      })
      .addCase(updateSortedColumn.rejected, (state, action) => {
        state.updateSortedColumnStatus = "failed";
        state.error = action.payload;
      })
      .addCase(build.pending, (state) => {
        state.previewStatus = "loading";
      })
      .addCase(build.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.previewLineup = action.payload;
          state.previewStatus = "succeeded";
        }
        if (state.progress) {
          state.previewStatus = "succeeded";
        }
      })
      .addCase(build.rejected, (state, action) => {
        state.previewStatus = "failed";
        state.error = action.payload;
      })
      .addCase(exclude.pending, (state) => {
        state.excludeStatus = "loading";
      })
      .addCase(exclude.fulfilled, (state, action) => {
        state.lockStatus = "idle";
        state.lockError = null;

        state.excludeStatus = "succeeded";
        state.excludeError = null;
        if (!(state.slateID in state.excluded)) {
          state.excluded[state.slateID] = [];
        }
        if (!state.excluded[state.slateID].includes(action.payload)) {
          state.excluded[state.slateID].push(action.payload);
        }
        const locked = state.locked[state.slateID] || [];
        state.locked[state.slateID] = locked.filter(
          (item) => item !== action.payload,
        );
      })
      .addCase(exclude.rejected, (state, action) => {
        state.excludeStatus = "failed";
        state.excludeError = action.error.message;
      })
      .addCase(unExclude.pending, (state) => {
        state.excludeStatus = "loading";
      })
      .addCase(unExclude.fulfilled, (state, action) => {
        state.lockStatus = "idle";
        state.lockError = null;

        state.excludeStatus = "succeeded";
        state.excludeError = null;
        if (!(state.slateID in state.excluded)) {
          state.excluded[state.slateID] = [];
        }
        const excluded = state.excluded[state.slateID] || [];
        state.excluded[state.slateID] = excluded.filter(
          (item) => item !== action.payload,
        );
      })
      .addCase(unExclude.rejected, (state, action) => {
        state.excludeStatus = "failed";
        state.excludeError = action.error.message;
      })
      .addCase(lock.pending, (state) => {
        state.lockStatus = "loading";
      })
      .addCase(lock.fulfilled, (state, action) => {
        state.excludeStatus = "idle";
        state.excludeError = null;

        state.lockStatus = "succeeded";
        state.lockError = null;
        if (!(state.slateID in state.locked)) {
          state.locked[state.slateID] = [];
        }
        state.locked[state.slateID] = state.locked[state.slateID].filter(
          (item) => item.split("|")[0] !== action.payload.split("|")[0],
        );
        state.locked[state.slateID].push(action.payload);
        const excluded = state.excluded[state.slateID] || [];
        state.excluded[state.slateID] = excluded.filter(
          (item) => item.split("|")[0] !== action.payload.split("|")[0],
        );
      })
      .addCase(lock.rejected, (state, action) => {
        state.lockStatus = "failed";
        state.lockError = action.error.message;
      })
      .addCase(saveLineup.pending, (state) => {
        state.saveLineupStatus = "loading";
      })
      .addCase(saveLineup.fulfilled, (state, action) => {
        state.savedLineups.push(action.payload);
        state.saveLineupStatus = "succeeded";
      })
      .addCase(saveLineup.rejected, (state, action) => {
        state.saveLineupStatus = "failed";
        state.error = action.payload;
      })
      .addCase(unSaveLineup.pending, (state) => {
        state.unSaveLineupStatus = "loading";
      })
      .addCase(unSaveLineup.fulfilled, (state, action) => {
        state.savedLineups = state.savedLineups.filter(
          (l) => l.players_id !== action.payload,
        );
        state.unSaveLineupStatus = "succeeded";
      })
      .addCase(unSaveLineup.rejected, (state, action) => {
        state.unSaveLineupStatus = "failed";
        state.error = action.payload;
      })
      .addCase(unlock.pending, (state) => {
        state.lockStatus = "loading";
      })
      .addCase(unlock.fulfilled, (state, action) => {
        state.excludeStatus = "idle";
        state.excludeError = null;

        state.lockStatus = "succeeded";
        state.lockError = null;
        if (!(state.slateID in state.locked)) {
          state.locked[state.slateID] = [];
        }
        const locked = state.locked[state.slateID] || [];
        state.locked[state.slateID] = locked.filter(
          (item) => item.split("|")[0] !== action.payload.split("|")[0],
        );
      })
      .addCase(unlock.rejected, (state, action) => {
        state.lockStatus = "failed";
        state.lockError = action.error.message;
      })
      .addCase(uploadLateSwap.pending, (state) => {
        state.uploadLateSwapStatus = "loading";
      })
      .addCase(uploadLateSwap.fulfilled, (state) => {
        state.uploadLateSwapStatus = "succeeded";
      })
      .addCase(uploadLateSwap.rejected, (state, action) => {
        state.uploadLateSwapStatus = "failed";
        state.lateSwapError = action.error.message;
      })
      .addCase(fetchContests.pending, (state) => {
        state.fetchContestsStatus = "loading";
      })
      .addCase(fetchContests.fulfilled, (state, action) => {
        state.contests = action.payload;
        state.fetchContestsStatus = "succeeded";
      })
      .addCase(fetchContests.rejected, (state, action) => {
        state.fetchContestsError = action.error.message;
        state.fetchContestsStatus = "failed";
      })
      .addCase(fetchCommonSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCommonSettings.fulfilled, (state, action) => {
        const data = action.payload;
        state.po_threshold = data.po_threshold;
        state.pfp_threshold = data.pfp_threshold;
        state.optimize_for_speed = data.optimize_for_speed;
        state.optimize_for_speed_value = data.optimize_for_speed_value;
        state.late_swap_rebuild_times_with_random =
          data.late_swap_rebuild_times_with_random;
        state.players_per_page = data.players_per_page;
        state.rebuild_with_default_settings_on_crash =
          data.rebuild_with_default_settings_on_crash;
        state.use_old_solver = data.use_old_solver;
        state.max_time_in_seconds = data.max_time_in_seconds;
        state.show_vegas_changes = data.show_vegas_changes;
      })
      .addCase(fetchCommonSettings.rejected, (state, action) => {
        state.state = "failed";
        state.error = action.payload;
      })
      .addCase(updateCommonSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCommonSettings.fulfilled, (state, action) => {
        const data = action.payload;
        state.po_threshold = data.po_threshold;
        state.pfp_threshold = data.pfp_threshold;
        state.optimize_for_speed = data.optimize_for_speed;
        state.optimize_for_speed_value = data.optimize_for_speed_value;
        state.late_swap_rebuild_times_with_random =
          data.late_swap_rebuild_times_with_random;
        state.players_per_page = data.players_per_page;
        state.rebuild_with_default_settings_on_crash =
          data.rebuild_with_default_settings_on_crash;
        state.use_old_solver = data.use_old_solver;
        state.max_time_in_seconds = data.max_time_in_seconds;
        state.show_vegas_changes = data.show_vegas_changes;
      })
      .addCase(updateCommonSettings.rejected, (state, action) => {
        state.state = "failed";
        state.error = action.payload;
      })
      .addCase("BUILD::MESSAGE", (state, action) => {
        buildFulfilled(state, action);
      })
      .addCase("PROCESSOR::MESSAGE", (state, action) =>
        receiveProcessorWebSocketMessageFn(state, action),
      )
      .addCase("PROCESSOR::OPEN", (state) => {
        state.wsProcessorError = false;
      })
      .addCase("PROCESSOR::ERROR", (state, action) => {
        state.wsProcessorError = true;
      });
  },
});

export default slatesSlice.reducer;

export const {
  stopBuild,
  resetBuild,
  resetExcludeErrorAndStatus,
  resetGames,
  resetLockErrorAndStatus,
  resetPastBuilds,
  resetPreviewLineup,
  resetSavedLineups,
  resetSlates,
  resetIsLateSwap,
  setIsInitialFetch,
  setSlateID,
  setTheDate,
  setBacktestingValue,
  setLateSwapData,
  setLoadingForPreviewStatus,
  setSortLineupBy,
  markWsMessagesAsShowed,
} = slatesSlice.actions;

export const selectAllDisabledDates = (state) => state.slates.disabledDates;
export const selectExcludeError = (state) => state.slates.excludeError;
export const selectExcludeStatus = (state) => state.slates.excludeStatus;
export const selectFetchBuildsStatus = (state) =>
  state.slates.fetchBuildsStatus;
export const selectFetchDisabledDatesStatus = (state) =>
  state.slates.fetchDisabledDatesStatus;
export const selectFetchGamesStatus = (state) => state.slates.fetchGamesStatus;
export const selectFetchMyDataStatus = (state) =>
  state.slates.fetchMyDataStatus;
export const selectFetchSavedLineupsStatus = (state) =>
  state.slates.fetchSavedLineupsStatus;
export const selectFetchSlatesStatus = (state) =>
  state.slates.fetchSlatesStatus;
export const selectFetchSortedColumnStatus = (state) =>
  state.slates.fetchSortedColumnStatus;
export const selectGames = (state) => state.slates.games;
export const selectIsInitialFetch = (state) => state.slates.isInitialFetch;
export const selectLockError = (state) => state.slates.lockError;
export const selectLockStatus = (state) => state.slates.lockStatus;
export const selectMessageFromWS = (state) => state.slates.messageFromWS;
export const selectMySettings = (state) => state.mySettings.entities;
export const selectPastBuilds = (state) => state.slates.pastBuilds;
export const selectPastBuildsStatus = (state) => state.slates.fetchBuildsStatus;
export const selectPreviewLineup = (state) => state.slates.previewLineup;
export const selectPreviewStatus = (state) => state.slates.previewStatus;
export const selectProgress = (state) => state.slates.progress;
export const selectSavedLineups = (state) => state.slates.savedLineups;
export const selectSlateID = (state) => state.slates.slateID;
export const selectSortedColumn = (state) => state.slates.sortedColumn;
export const selectTeamStackStatistic = (state) =>
  state.slates.teamStacksStatistic;
export const selectTheDate = (state) => state.slates.theDate;
export const selectUpdateMyDataStatus = (state) =>
  state.slates.updateMyDataStatus;
export const selectUpdateSortedColumnStatus = (state) =>
  state.slates.updateSortedColumnStatus;
export const selectBacktestingValue = (state) => state.slates.backtestingValue;
export const selectIsLateSwap = (state) => state.slates.isLateSwap;
export const selectNotAvailableGamesSlate = (state) =>
  state.slates.notAvailableGamesSlate;
export const selectSortLineupBy = (state) => state.slates.sortLineupBy;

export const selectContests = (state) => state.slates.contests;

export const selectTeams = createSelector(
  [(state) => state.slates.games],
  (games) => {
    const teams = [];
    if (Array.isArray(teams)) {
      for (let game of games) {
        teams.push(game.team_away);
        teams.push(game.team_home);
      }
    }
    return teams;
  },
);

export const selectExcluded = createSelector(
  [(state) => state.slates.excluded, (state) => state.slates.slateID],
  (excluded, slateID) => excluded[slateID] || [],
);

export const selectLocked = createSelector(
  [(state) => state.slates.locked, (state) => state.slates.slateID],
  (locked, slateID) => locked[slateID] || [],
);

export const selectCurrentSlate = (state) => {
  const slateID = state.slates.slateID;
  return state.slates.entities[slateID];
};

export const selectCurrentSlateVariety = createSelector(
  [selectCurrentSlate],
  (slate) => {
    if (!slate) {
      return "classic";
    } else {
      return slate.variety;
    }
  },
);

export const selectCurrentSlateStarted = createSelector(
  [selectCurrentSlate],
  (slate) => {
    if (!slate) {
      return true;
    } else {
      return new Date() > new Date(slate.timestamp);
    }
  },
);

export const selectLateSwapData = createSelector(
  [(state) => state.slates.lateSwapData, (state) => state.slates.slateID],
  (lateSwapData, slateID) => {
    return lateSwapData[slateID] || {};
  },
);

export const selectLateSwapDataId = (state) => {
  return (
    (state.slates.lateSwapData[state.slates.slateID] || {}).lateSwapId || null
  );
};

function lineupPlayerSameAsSlatePlayer(lineupPlayer, slatePlayer) {
  if (slatePlayer.id.includes(":")) {
    return lineupPlayer.id == slatePlayer.id;
  } else {
    return lineupPlayer.id.split(":")[0] === slatePlayer.id.split(":")[0];
  }
}

export const selectLineups = createSelector(
  [
    (state) => state.slates.lineups,
    (state) => state.slates.savedLineups,
    selectAllPlayers,
    selectExcluded,
    selectLocked,
    selectSlateID,
    selectActiveMySetting,
  ],
  (
    lineups,
    savedLineups,
    slatePlayers,
    excludedIDs,
    lockedIDs,
    slateID,
    mySetting,
  ) => {
    const savedLineupsIDs = savedLineups.map((lineup) => lineup.players_id);
    const myData = (mySetting || {}).custom_data || {
      myProj: {},
      myRand: {},
      myMin: {},
      myMax: {},
      myPo: {},
      myLine: {},
      myPPLine: {},
      myBO: {},
    };

    return lineups.map((lineup) => ({
      ...lineup,
      isSaved: savedLineupsIDs.includes(
        lineup.players.map((p) => p.id).join(""),
      ),
      players: lineup.players.map((lineupPlayer) => {
        const slatePlayer = slatePlayers.find((slatePlayer) =>
          lineupPlayerSameAsSlatePlayer(lineupPlayer, slatePlayer),
        );
        if (slatePlayer) {
          return {
            ...lineupPlayer,
            excluded: excludedIDs.includes(slatePlayer.id),
            locked: lockedIDs.includes(slatePlayer.id),
            slateId: slatePlayer.id,
          };
        }
        return lineupPlayer;
      }),
    }));
  },
);

export function selectLineupsAggregationFunc() {
  return (lineups, excludedIDs, lockedIDs, slateID, mySettings) => {
    lockedIDs = lockedIDs.map((lockedID) => lockedID.split("|")[0]);
    const mySetting = Object.values(mySettings).find(
      (s) => s.slate_id === slateID && s.is_active,
    );
    const myData = (mySetting || {}).custom_data || {
      myProj: {},
      myRand: {},
      myMin: {},
      myMax: {},
      myPo: {},
      myLine: {},
      myPPLine: {},
      myBO: {},
    };

    const players = {};
    const lineupsNum = lineups.length;
    const totalCounter = {};

    lineups.forEach((lineup) => {
      lineup.players.forEach((player) => {
        const counter = players[player.id] ? players[player.id].counter + 1 : 1;
        const baseId = player.id.split(":")[0];
        if (!totalCounter.hasOwnProperty(baseId)) {
          totalCounter[baseId] = 0;
        }
        totalCounter[baseId]++;
        players[player.id] = {
          ...player,
          counter,
          totalPO: totalCounter[baseId] / lineupsNum,
          actualPO: counter / lineupsNum,
          excluded: excludedIDs.includes(player.id),
          locked: lockedIDs.includes(player.id),
          max: myData.myMax[player.name],
          min: myData.myMin[player.name],
          my_po: myData.myPo[player.name],
          my_projection: myData.myProj[player.name],
          randomness: myData.myRand[player.name],
          line: myData.myLine[player.name],
          pp_line: myData.myPPLine[player.name],
          my_bo: myData.myBO[player.name],
        };
      });
    });
    lineups.forEach((lineup) => {
      lineup.players.forEach(
        (player) =>
          (players[player.id] = {
            ...players[player.id],
            totalPO: totalCounter[player.id.split(":")[0]] / lineupsNum,
          }),
      );
    });

    return Object.values(players).sort(function (a, b) {
      if (a.totalPO === b.totalPO) {
        if (a.name === b.name) {
          // If name is equal, sort by actualPO
          return b.actualPO - a.actualPO;
        }
        // If totalPO is equal, sort by name
        return a.name > b.name ? 1 : -1;
      }
      // Sort by totalPO
      return b.totalPO - a.totalPO;
    });
  };
}

export const selectLineupsAggregation = createSelector(
  [
    selectLineups,
    selectExcluded,
    selectLocked,
    selectSlateID,
    selectMySettings,
  ],
  selectLineupsAggregationFunc(),
);

export const selectPreviewLineupWithLockStatus = createSelector(
  [selectPreviewLineup, selectExcluded, selectLocked, selectCurrentSlate],
  (lineup, excludedIDs, lockedIDs, slate) => {
    return lineup.map((p) => ({
      ...p,
      id: slate.assemble_rules ? `${p.id}:${p.pos}` : p.id,
      excluded: slate.assemble_rules
        ? excludedIDs.includes(`${p.id}:${p.pos}`)
        : excludedIDs.includes(p.id),
      locked: slate.assemble_rules
        ? lockedIDs.includes(`${p.id}:${p.pos}`)
        : lockedIDs.includes(p.id),
    }));
  },
);

export const selectPreviewButtonStatus = createSelector(
  [
    selectExcluded,
    selectLocked,
    selectSlateID,
    selectMySettings,
    (state) => state?.user?.profile?.is_staff,
  ],
  (excludedIDs, lockedIDs, slateID, mySettings, isStaff) => {
    if (isStaff) {
      return true;
    }
    const mySetting = Object.values(mySettings).find(
      (s) => s.slate_id === slateID && s.is_active,
    );
    const myData = (mySetting || {}).custom_data || {
      myProj: {},
      myRand: {},
      myMin: {},
      myMax: {},
      myPo: {},
      myLine: {},
      myPPLine: {},
      myBO: {},
    };
    const myPFPs = myData.myProj;
    const myRands = myData.myRand;
    const changes =
      excludedIDs.length +
      lockedIDs.length +
      Object.keys(myPFPs).length +
      Object.keys(myRands).length;
    return changes >= 2;
  },
);

export const selectSuccessLineupCount = createSelector(
  [selectLineups, selectBacktestingValue],
  (lineups, backtestingValue) => {
    return lineups.filter(
      (lineup) => parseFloat(lineup.actual) >= backtestingValue,
    ).length;
  },
);

export const selectHighestActualLineupPoints = createSelector(
  [selectLineups],
  (lineups) => {
    if (!lineups.length) {
      return "0.00";
    }
    return Math.max(
      ...(lineups.map((lineup) => parseFloat(lineup.actual || "0")) || [0]),
    ).toFixed(2);
  },
);

export const selectAverageActualLineupPoints = createSelector(
  [selectLineups],
  (lineups) => {
    if (!lineups.length) {
      return "0.00";
    }
    return (
      lineups.reduce((sum, lineup) => sum + parseFloat(lineup.actual), 0) /
      lineups.length
    ).toFixed(2);
  },
);

export const selectLineupsSalaryDistribution = createSelector(
  [selectLineups],
  (lineups) => {
    if (!lineups.length) {
      return {};
    }
    const stats = {};
    const lineupSalaries = lineups.map((l) => l.cost);
    const minSalary = Math.min(...lineupSalaries);
    const maxSalary = Math.max(...lineupSalaries);
    let step = 500;
    if (minSalary < 500) {
      step = 5;
    }

    for (
      let i = minSalary - (minSalary % step);
      i < maxSalary + step - (maxSalary % step);
      i += step
    ) {
      const lineupsInRange = lineupSalaries.filter(
        (cost) => i < cost && cost <= i + step,
      );
      if (lineupsInRange.length > 0) {
        stats[`${i}-${i + step}`] = lineupsInRange.length;
      }
    }
    return stats;
  },
);

export const selectTeamsDistribution = createSelector(
  [selectLineups],
  (lineups) => {
    if (!lineups.length) {
      return [];
    }
    // Flattening the array of lineups to an array of players
    let players = [].concat.apply(
      [],
      lineups.map((lineup) => lineup.players),
    );

    if (players.some((player) => !player.team)) {
      return [];
    }

    // Counting players for each team
    let teamCounts = players.reduce((counts, player) => {
      counts[player.team] = (counts[player.team] || 0) + 1;
      return counts;
    }, {});

    // Calculating total number of players
    let totalPlayers = players.length;

    // Calculating percentage and reforming data into an array of objects
    let teamPercentages = Object.keys(teamCounts).map((team) => ({
      team: team,
      percentage: roundToTwoDecimals((teamCounts[team] / totalPlayers) * 100),
    }));

    // Sorting the array in decreasing order of the percentage
    teamPercentages.sort((a, b) => b.percentage - a.percentage);

    return teamPercentages;
  },
);

export const selectCommonSettings = createSelector(
  [
    (state) => state.slates,
    (state) => state.rebuild_with_default_settings_on_crash,
  ],
  (slates, rebuild_with_default_settings_on_crash) => ({
    po_threshold: slates.po_threshold,
    pfp_threshold: slates.pfp_threshold,
    optimize_for_speed: slates.optimize_for_speed,
    optimize_for_speed_value: slates.optimize_for_speed_value,
    late_swap_rebuild_times_with_random:
      slates.late_swap_rebuild_times_with_random,
    players_per_page: slates.players_per_page,
    rebuild_with_default_settings_on_crash,
    use_old_solver: slates.use_old_solver,
    max_time_in_seconds: slates.max_time_in_seconds,
    show_vegas_changes: slates.show_vegas_changes,
  }),
);

export const selectPlayersPerPage = (state) => state.slates.players_per_page;

export const selectWsProcessorError = (state) => state.slates.wsProcessorError;

export const selectShowVegasChanges = (state) =>
  state.slates.show_vegas_changes;

export const {
  selectAll: selectAllSlates,
  selectById: selectSlateById,
  selectIds: selectSlateIds,
} = slatesAdapter.getSelectors((state) => state.slates);
