const rangesBySports = {
  cbb: {
    fanduel: {
      classic: {
        value: [40000, 50000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
      flexy_five: {
        value: [45000, 55000],
        step: 100,
      },
    },
  },
  cfb: {
    fanduel: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  cfl: {
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
      "single game": {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  cs: {
    fanduel: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [70000, 100000],
        step: 100,
      },
      "single game": {
        value: [40000, 70000],
        step: 100,
      },
    },
  },
  el: {
    fanduel: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [60000, 70000],
        step: 100,
      },
    },
  },
  golf: {
    fanduel: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    yahoo: {
      classic: {
        value: [100, 200],
        step: 1,
      },
    },
    superdraft: {
      classic: {
        value: [50000, 80000],
        step: 100,
      },
      multiplier: {
        value: [6, 6],
        step: 1,
      },
    },
  },
  lol: {
    fanduel: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  mlb: {
    fanduel: {
      classic: {
        value: [25000, 35000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [70000, 100000],
        step: 100,
      },
    },
    yahoo: {
      classic: {
        value: [100, 200],
        step: 1,
      },
    },
    ownersbox: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
      "single game": {
        value: [15000, 27500],
        step: 100,
      },
    },
    superdraft: {
      classic: {
        value: [50000, 80000],
        step: 100,
      },
      multiplier: {
        value: [8, 8],
        step: 1,
      },
      champion_mode: {
        value: [5, 5],
        step: 1,
      },
    },
    ikb: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  mma: {
    fanduel: {
      classic: {
        value: [0, 100],
        step: 1,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [100000, 130000],
        step: 100,
      },
    },
  },
  nascar: {
    fanduel: {
      classic: {
        value: [40000, 50000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    superdraft: {
      classic: {
        value: [50000, 80000],
        step: 100,
      },
    },
  },
  f1: {
    fanduel: {
      classic: {
        value: [40000, 50000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  nba: {
    fanduel: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
      Flex3: {
        value: [0, 7],
        step: 1,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [60000, 70000],
        step: 100,
      },
    },
    yahoo: {
      classic: {
        value: [100, 200],
        step: 1,
      },
      "single game": {
        value: [100, 145],
        step: 1,
      },
    },
    superdraft: {
      classic: {
        value: [50000, 80000],
        step: 100,
      },
      multiplier: {
        value: [7, 7],
        step: 1,
      },
      champion_mode: {
        value: [5, 5],
        step: 1,
      },
    },
    ownersbox: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
      "single game": {
        value: [18000, 27500],
        step: 100,
      },
    },
    ikb: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  nfl: {
    fanduel: {
      classic: {
        value: [50000, 60000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [60000, 130000],
        step: 100,
      },
    },
    yahoo: {
      classic: {
        value: [100, 200],
        step: 1,
      },
      "single game": {
        value: [100, 145],
        step: 1,
      },
    },
    nftdraft: {
      classic: {
        value: [100, 130],
        step: 1,
      },
    },
    superdraft: {
      classic: {
        value: [50000, 80000],
        step: 100,
      },
      multiplier: {
        value: [8, 8],
        step: 1,
      },
    },
    ownersbox: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
      "single game": {
        value: [20000, 30000],
        step: 100,
      },
    },
  },
  nhl: {
    fanduel: {
      classic: {
        value: [45000, 55000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [60000, 130000],
        step: 100,
      },
    },
    yahoo: {
      classic: {
        value: [100, 200],
        step: 1,
      },
      "single game": {
        value: [100, 145],
        step: 1,
      },
    },
    superdraft: {
      classic: {
        value: [50000, 80000],
        step: 100,
      },
      multiplier: {
        value: [8, 8],
        step: 1,
      },
    },
    ownersbox: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  soccer: {
    fanduel: {
      classic: {
        value: [0, 100],
        step: 1,
      },
      "single game": {
        value: [0, 50],
        step: 1,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [60000, 130000],
        step: 100,
      },
    },
  },
  tennis: {
    fanduel: {
      classic: {
        value: [0, 100],
        step: 1,
      },
      "single game": {
        value: [0, 50],
        step: 1,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    fanteam: {
      classic: {
        value: [60000, 130000],
        step: 100,
      },
    },
  },
  wnba: {
    fanduel: {
      classic: {
        value: [30000, 40000],
        step: 100,
      },
    },
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
    },
    superdraft: {
      classic: {
        value: [50000, 80000],
        step: 100,
      },
      multiplier: {
        value: [8, 8],
        step: 1,
      },
    },
  },
  xfl: {
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
      "single game": {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
  usfl: {
    draftkings: {
      classic: {
        value: [30000, 50000],
        step: 100,
      },
      "single game": {
        value: [30000, 50000],
        step: 100,
      },
    },
  },
};

const specialPositionsBySports = {
  cbb: {
    fanduel: {
      classic: {
        UTIL: ["G", "F"],
      },
      "single game": {
        MVP: ["G", "F"],
        STAR: ["G", "F"],
        PRO: ["G", "F"],
        UTIL: ["G", "F"],
      },
    },
    draftkings: {
      classic: {
        UTIL: ["G", "F"],
      },
      "single game": {
        CPT: ["G", "F"],
        UTIL: ["G", "F"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  cfb: {
    fanduel: {
      classic: {
        SuperFLEX: ["QB", "RB", "WR"],
      },
      "single game": {
        MVP: ["QB", "RB", "WR"],
        FLEX: ["QB", "RB", "WR"],
      },
    },
    draftkings: {
      classic: {
        FLEX: ["QB", "RB", "WR"],
        SuperFLEX: ["QB", "RB", "WR"],
      },
      "single game": {
        CPT: ["QB", "RB", "WR"],
        UTIL: ["QB", "RB", "WR"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  cfl: {
    draftkings: {
      classic: {
        FLEX: ["QB", "RB", "WR"],
        SuperFLEX: ["QB", "RB", "WR"],
      },
      "single game": {
        CPT: ["QB", "RB", "WR"],
        UTIL: ["QB", "RB", "WR"],
      },
    },
  },
  cs: {
    fanduel: {
      classic: {
        MVP: ["P", "TEAM"],
      },
    },
    draftkings: {
      classic: {
        CPT: ["P"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  el: {
    fanduel: {
      classic: {
        UTIL: ["G", "F"],
      },
      "single game": {
        MVP: ["G", "F"],
        STAR: ["G", "F"],
        PRO: ["G", "F"],
        UTIL: ["G", "F"],
      },
    },
    draftkings: {
      classic: {
        UTIL: ["G", "F"],
      },
      "single game": {
        CPT: ["G", "F"],
        UTIL: ["G", "F"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  golf: {
    fanduel: {
      classic: undefined,
    },
    draftkings: {
      classic: undefined,
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  lol: {
    fanduel: {
      classic: {
        STAR: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
      },
      "single game": {
        MVP: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
        STAR: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
        UTIL: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
      },
    },
    draftkings: {
      classic: {
        CPT: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
      },
      "single game": {
        MVP: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
        STAR: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
        UTIL: ["TOP", "JNG", "MID", "ADC", "SUP", "TEAM"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  mlb: {
    fanduel: {
      classic: {
        "C/1B": ["C", "1B"],
        UTIL: ["C", "1B", "2B", "3B", "SS", "OF"],
      },
      "single game": {
        MVP: ["C", "1B", "2B", "3B", "SS", "OF"],
        STAR: ["C", "1B", "2B", "3B", "SS", "OF"],
        UTIL: ["C", "1B", "2B", "3B", "SS", "OF"],
      },
    },
    draftkings: {
      classic: undefined,
      "single game": {
        CPT: ["P", "C", "1B", "2B", "3B", "SS", "OF"],
        UTIL: ["P", "C", "1B", "2B", "3B", "SS", "OF"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
    ownersbox: {
      classic: {
        SF: ["IF", "OF", "P"],
      },
      "single game": {
        SG: ["IF", "OF", "P"],
        FLEX: ["IF", "OF"],
      },
    },
    ikb: {
      classic: {
        P: ["P", "SP", "RP"],
        OF: ["RF", "LF", "OF", "CF"],
      },
    },
  },
  mma: {
    fanduel: {
      classic: undefined,
    },
    draftkings: {
      classic: undefined,
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  nascar: {
    fanduel: {
      classic: undefined,
    },
    draftkings: {
      classic: undefined,
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  f1: {
    fanduel: {
      classic: undefined,
    },
    draftkings: {
      classic: undefined,
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  nba: {
    fanduel: {
      classic: undefined,
      "single game": {
        MVP: ["PG", "SG", "SF", "PF", "C"],
        STAR: ["PG", "SG", "SF", "PF", "C"],
        PRO: ["PG", "SG", "SF", "PF", "C"],
        UTIL: ["PG", "SG", "SF", "PF", "C"],
      },
      Flex3: {
        MVP: ["PG", "SG", "SF", "PF", "C"],
        STAR: ["PG", "SG", "SF", "PF", "C"],
        UTIL: ["PG", "SG", "SF", "PF", "C"],
      },
    },
    draftkings: {
      classic: {
        G: ["PG", "SG", "SF", "PF", "C"],
        F: ["PG", "SG", "SF", "PF", "C"],
        UTIL: ["PG", "SG", "SF", "PF", "C"],
      },
      "single game": {
        CPT: ["PG", "SG", "SF", "PF", "C"],
        UTIL: ["PG", "SG", "SF", "PF", "C"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: {
        G: ["PG", "SG"],
        F: ["SF", "PF"],
        UTIL: ["PG", "SG", "SF", "PF", "C"],
      },
      "single game": {
        MEGASTAR: ["PG", "SG", "SF", "PF", "C"],
        SUPERSTAR: ["PG", "SG", "SF", "PF", "C"],
        STAR: ["PG", "SG", "SF", "PF", "C"],
        FLEX: ["PG", "SG", "SF", "PF", "C"],
      },
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
      champion_mode: {
        CHP: ["PG", "SG", "SF", "PF", "C"],
        UTIL: ["PG", "SG", "SF", "PF", "C"],
      },
    },
    ownersbox: {
      classic: undefined,
      "single game": {
        SF: ["G", "FC"],
      },
    },
    ikb: {
      classic: undefined,
    },
  },
  nfl: {
    fanduel: {
      classic: {
        FLEX: ["QB", "RB", "WR", "TE", "DEF"],
      },
      "single game": {
        MVP: ["QB", "RB", "WR", "TE", "K", "DEF"],
        AnyFLEX: ["QB", "RB", "WR", "TE", "K", "DEF"],
      },
    },
    draftkings: {
      classic: {
        FLEX: ["QB", "RB", "WR", "TE", "DST"],
      },
      "single game": {
        CPT: ["QB", "RB", "WR", "TE", "DST", "K"],
        FLEX: ["QB", "RB", "WR", "TE", "DST", "K"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    nftdraft: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
    ownersbox: {
      classic: {
        FLEX: ["RB", "WR", "TE"],
        SUPERFLEX: ["QB", "RB", "WR", "TE"],
      },
      "single game": {
        FLEX: ["QB", "RB", "WR", "TE"],
        SUPERFLEX: ["QB", "RB", "WR", "TE"],
      },
    },
  },
  nhl: {
    fanduel: {
      classic: {
        UTIL: ["C", "W", "D", "G"],
      },
      "single game": {
        MVP: ["C", "W", "D", "G"],
        UTIL: ["C", "W", "D", "G"],
      },
    },
    draftkings: {
      classic: {
        UTIL: ["C", "W", "D", "G"],
      },
      "single game": {
        CPT: ["C", "W", "D", "G"],
        FLEX: ["C", "W", "D", "G"],
      },
    },
    fanteam: {
      classic: {
        UTIL: ["C", "W", "D"],
      },
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
    ownersbox: {
      classic: {
        SF: ["F", "D", "G"],
      },
    },
  },
  soccer: {
    fanduel: {
      "single game": {
        CPT: ["FWD", "MID", "DEF", "GK"],
      },
    },
    draftkings: {
      classic: {
        UTIL: ["F", "M", "D", "GK"],
      },
      "single game": {
        CPT: ["F", "M", "D", "GK"],
        FLEX: ["F", "M", "D", "GK"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  tennis: {
    fanduel: {
      "single game": undefined,
    },
    draftkings: {
      classic: undefined,
      "single game": undefined,
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  wnba: {
    fanduel: {
      classic: undefined,
      "single game": {
        MVP: ["G", "F"],
        STAR: ["G", "F"],
        PRO: ["G", "F"],
        UTIL: ["G", "F"],
      },
    },
    draftkings: {
      classic: { UTIL: ["G", "F"] },
      "single game": {
        CPT: ["G", "F"],
        UTIL: ["G", "F"],
      },
    },
    fanteam: {
      classic: undefined,
    },
    yahoo: {
      classic: undefined,
    },
    superdraft: {
      classic: undefined,
      multiplier: undefined,
    },
  },
  xfl: {
    draftkings: {
      classic: {
        "WE/TE": ["WR", "TE"],
        FLEX: ["WR", "RB", "TE"],
      },
      "single game": {
        CPT: ["QB", "WR", "RB", "TE", "K", "DST"],
        FLEX: ["QB", "WR", "RB", "TE", "K", "DST"],
      },
    },
  },
  usfl: {
    draftkings: {
      classic: {
        "WE/TE": ["WR", "TE"],
        FLEX: ["WR", "RB", "TE"],
      },
      "single game": {
        CPT: ["QB", "WR", "RB", "TE", "K", "DST"],
        FLEX: ["QB", "WR", "RB", "TE", "K", "DST"],
      },
    },
  },
};

const getInitSalaryRangeBySports = (
  sports,
  site,
  slateVariety,
  slateSalary = null,
) => {
  if (slateSalary) {
    if (
      ["multiplier", "champion_mode", "super_champ_mode"].includes(slateVariety)
    ) {
      return [slateSalary, slateSalary, 1];
    }
    if (slateSalary > 10000) {
      let minSalary = slateVariety.includes("single") ? 0 : slateSalary - 20000;
      return [minSalary, slateSalary, 1000];
    } else if (slateSalary > 1000) {
      let minSalary = slateVariety.includes("single") ? 0 : slateSalary - 3000;
      return [minSalary, slateSalary, 100];
    } else {
      let minSalary = slateVariety.includes("single") ? 0 : slateSalary - 100;
      return [minSalary, slateSalary, 10];
    }
  }
  let config, minValue, maxValue, step;

  if (rangesBySports[sports][site][slateVariety] !== undefined) {
    config = rangesBySports[sports][site][slateVariety];
  } else if (slateVariety && slateVariety.includes("single")) {
    config = rangesBySports[sports][site]["classic"];
    config[0] = 0;
  } else {
    config = rangesBySports[sports][site]["classic"];
  }
  [minValue, maxValue] = config.value;
  step = config.step;
  return [minValue, maxValue, step];
};

const getInitMaxUnique = (sports, site) => {
  const uniques = {
    cbb: { fanduel: 8, draftkings: 7, fanteam: 6 },
    cfb: { fanduel: 8, draftkings: 7, fanteam: 6 },
    cs: { fanduel: 6, draftkings: 6, fanteam: 6 },
    el: { fanduel: 5, draftkings: 5, fanteam: 6 },
    golf: { fanduel: 7, draftkings: 7, fanteam: 6, superdraft: 6 },
    lol: { fanduel: 7, draftkings: 7, fanteam: 6 },
    mlb: {
      fanduel: 7,
      draftkings: 7,
      fanteam: 6,
      yahoo: 8,
      ownersbox: 8,
      superdraft: 8,
    },
    mma: { fanduel: 7, draftkings: 7, fanteam: 6 },
    nascar: { fanduel: 7, draftkings: 7, fanteam: 6 },
    f1: { fanduel: 7, draftkings: 7, fanteam: 6 },
    nba: {
      fanduel: 9,
      draftkings: 8,
      fanteam: 6,
      yahoo: 8,
      superdraft: 6,
      ownersbox: 8,
    },
    nfl: {
      fanduel: 9,
      draftkings: 8,
      fanteam: 6,
      yahoo: 8,
      nftdraft: 9,
      ownersbox: 8,
    },
    nhl: {
      fanduel: 7,
      draftkings: 7,
      fanteam: 6,
      yahoo: 8,
      superdraft: 7,
      ownersbox: 9,
    },
    soccer: { fanduel: 7, draftkings: 7, fanteam: 6 },
    wnba: { fanduel: 7, draftkings: 6, fanteam: 6 },
    tennis: { fanduel: 7, draftkings: 6, fanteam: 6 },
    xfl: { draftkings: 6 },
    usfl: { draftkings: 6 },
    cfl: { draftkings: 6 },
  };

  return uniques[sports][site];
};

const getInitMaxOpponent = (sports) => {
  const values = {
    cs: 4,
    lol: 4,
    mlb: 4,
    mma: 1,
    nfl: 8,
    nhl: 4,
    soccer: 4,
    tennis: 1,
    xfl: 5,
    usfl: 5,
    cfl: 5,
  };

  return values[sports];
};

const getInitRangePlayerPerTeam = (
  sports,
  site,
  slateVariety,
  totalPlayers,
) => {
  if (slateVariety === "tiers") {
    return [0, totalPlayers - 1];
  }
  if (slateVariety !== "classic" && slateVariety !== "multiplier") {
    return [1, totalPlayers - 1];
  }
  const values = {
    cbb: { fanduel: 4, draftkings: 7, fanteam: 4 },
    cfb: { fanduel: 4, draftkings: 7, fanteam: 4 },
    cs: { fanduel: 4, draftkings: 3, fanteam: 4 },
    el: { fanduel: 4, draftkings: 7, fanteam: 4 },
    lol: { fanduel: 4, draftkings: 4, fanteam: 4 },
    mlb: {
      fanduel: 5,
      draftkings: 6,
      fanteam: 4,
      yahoo: 8,
      ownersbox: 5,
      superdraft: 5,
      ikb: 6,
    },
    nba: {
      fanduel: 4,
      draftkings: 7,
      fanteam: 4,
      yahoo: 6,
      superdraft: 4,
      ownersbox: 4,
      ikb: 4,
    },
    nfl: {
      fanduel: 4,
      draftkings: 7,
      fanteam: 4,
      yahoo: 6,
      nftdraft: 10,
      ownersbox: 4,
    },
    nhl: {
      fanduel: 4,
      draftkings: 7,
      fanteam: 4,
      yahoo: 6,
      superdraft: 4,
      ownersbox: 4,
    },
    soccer: { fanduel: 4, draftkings: 7, fanteam: 4 },
    wnba: { fanduel: 4, draftkings: 7, fanteam: 4 },
    tennis: { fanduel: 1, draftkings: 1, fanteam: 1 },
    xfl: { draftkings: 6 },
    usfl: { draftkings: 6 },
  };
  return [0, values[sports][site]];
};

const getInitialSpecialPositions = (sports, site, slateVariety) => {
  return specialPositionsBySports[sports][site][slateVariety];
};

const getMaxOpponentLabel = (sports) => {
  const values = {
    cs: "MAX OFFENSIVE VS TEAM",
    lol: "MAX OFFENSIVE VS TEAM",
    mlb: "MAX OFFENSIVE VS PITCHER",
    mma: "MAX FIGHTER VS OPPONENT",
    nfl: "MAX OFFENSIVE VS DEFENSE",
    nhl: "MAX OFFENSIVE VS GOALIE",
    soccer: "MAX OFFENSIVE VS GOALIE",
    tennis: "MAX OPPONENT PLAYER",
    xfl: "MAX OFFENSIVE VS DEFENSE",
    usfl: "MAX OFFENSIVE VS DEFENSE",
    cfl: "MAX OFFENSIVE VS DEFENSE",
  };

  return values[sports];
};

const getMaxOpponentTips = (sports) => {
  const values = {
    cs: "The Max Offensive slider adjusts the maximum number of players used from the opposing team of your team slot.",
    lol: "The Max Offensive slider adjusts the maximum number of players used from the opposing team of your team slot.",
    mlb: "Maximum amount of offensive players against your defense. Not applicable to all sports.",
    mma: "The Max Offensive slider adjusts the maximum number of fighters matched up against other fighters in your lineup.",
    nfl: "Maximum amount of offensive players against your defense. Not applicable to all sports.",
    nhl: "Maximum amount of offensive players against your defense. Not applicable to all sports.",
    soccer:
      "The Max Offensive slider adjusts the maximum number of players used from the opposing team of your goalie.",
    tennis:
      "The Max Opponent slider set adjust available opposing players in lineup or not.",
    xfl: "Maximum amount of offensive players against your defense. Not applicable to all sports.",
    usfl: "Maximum amount of offensive players against your defense. Not applicable to all sports.",
    cfl: "Maximum amount of offensive players against your defense. Not applicable to all sports.",
  };

  return values[sports];
};

const getMaxTotalOwnerships = (slate) => {
  // for now decided to use 1000 for all slates
  return 1000;
  // if (!slate) {
  //   return 600;
  // }
  // return slate.total_players * 80;
};

const getChooseSlateSportChildren = (sports, children) => {
  let child = JSON.parse(JSON.stringify(children));
  sports = sports === "euroleague" ? "el" : sports;
  for (let site of [
    "fanduel",
    "draftkings",
    "yahoo",
    "fanteam",
    "superdraft",
    "ownersbox",
    "ikb",
  ]) {
    if (!rangesBySports[sports][site]) {
      child = child.filter((c) => c.value !== site);
    }
  }
  return child;
};

const getTemplateInitialValues = (
  sports,
  slate,
  minSalary,
  maxSalary,
  specialPositionsInitial,
) => {
  let initialValues = {
    lineup_count: 3,
    min_projection: 0,
    max_exposure: "100.0",
    max_opponent: getInitMaxOpponent(sports),
    max_total_ownerships: getMaxTotalOwnerships(slate),
    max_difference_exposure: 100,
    use_max_difference_exposure: false,
    model_settings: {},
    name: "",
    optimize_by: "pfp",
    ownership_rules: [],
    additional_settings: {},
    randomness: 0,
    salary_range: [minSalary, maxSalary],
    special_positions: specialPositionsInitial,
    team_stacks: [],
    maximize_coefficient: 40,
    unique: 1,
    sliding_unique_range: [1, 1],
    use_sliding_unique: false,
  };

  if (sports === "nhl") {
    initialValues.additional_settings = {
      lines_allowed: ["L1", "L2", "PP1", "PP2"],
    };
  }
  return initialValues;
};

const getInitialTeamStack = (sports, positions) => {
  const initStack = {
    stackSize: null,
    positions,
    teams: {},
    uniques: 0,
    uniques_all_stacks: false,
    advanced_stack: {},
  };

  if (sports === "mlb") {
    initStack.batting_order = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
    initStack.gap = 8;
  }
  if (sports === "nhl") {
    initStack.lines = ["L1", "L2", "PP1", "only_same_line", "only_attack"];
  }

  return initStack;
};

export {
  rangesBySports,
  getInitMaxOpponent,
  getInitMaxUnique,
  getInitRangePlayerPerTeam,
  getInitSalaryRangeBySports,
  getInitialSpecialPositions,
  getMaxOpponentLabel,
  getMaxOpponentTips,
  getMaxTotalOwnerships,
  getChooseSlateSportChildren,
  getTemplateInitialValues,
  getInitialTeamStack,
};
