import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { axiosInstanceV2 } from "network/apis";
import {
  createProfileTemplateFunc,
  deleteProfileTemplateFunc,
  updateProfileTemplateFunc
} from "store/profileTemplateFunctions";

const profileTemplatesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id
});

export const initialState = profileTemplatesAdapter.getInitialState({
  createProfileTemplateStatus: "idle",
  deleteProfileTemplateStatus: "idle",
  fetchProfileTemplateStatus: "idle",
  updateProfileTemplateStatus: "idle",
  error: null
});

export const fetchProfileTemplates = createAsyncThunk(
  "profiles/fetchProfileTemplates",
  async ({ sports, site, slate_variety }) => {
    const response = await axiosInstanceV2.get(`/core/profile_template/`, {
      params: { sports, site, slate_variety }
    });
    return { profileTemplates: response.data };
  }
);

export const createProfileTemplate = createAsyncThunk(
  "slates/createProfileTemplate",
  createProfileTemplateFunc
);

export const deleteProfileTemplate = createAsyncThunk(
  "slates/deleteProfileTemplate",
  deleteProfileTemplateFunc
);

export const updateProfileTemplate = createAsyncThunk(
  "slates/updateProfileTemplate",
  updateProfileTemplateFunc
);

const profileTemplatesSlice = createSlice({
  name: "profileTemplates",
  initialState,
  reducers: {
    resetProfileTemplates(state) {
      state.fetchProfileTemplateStatus = "idle";
      state.error = null;
      profileTemplatesAdapter.removeAll(state);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProfileTemplates.pending, state => {
        state.fetchProfileTemplateStatus = "loading";
      })
      .addCase(fetchProfileTemplates.fulfilled, (state, action) => {
        state.fetchProfileTemplateStatus = "succeeded";
        const { profileTemplates } = action.payload;
        profileTemplatesAdapter.setAll(state, profileTemplates);
      })
      .addCase(fetchProfileTemplates.rejected, (state, action) => {
        state.fetchProfileTemplateStatus = "failed";
        state.error = action.payload;
      })
      .addCase(createProfileTemplate.pending, state => {
        state.createProfileTemplateStatus = "loading";
      })
      .addCase(createProfileTemplate.fulfilled, (state, action) => {
        state.createProfileTemplateStatus = "succeeded";
        const { profileTemplate } = action.payload;
        if (profileTemplate.id) {
          profileTemplatesAdapter.upsertOne(state, profileTemplate);
        }
      })
      .addCase(createProfileTemplate.rejected, (state, action) => {
        state.createProfileTemplateStatus = "failed";
        state.error = action.payload;
      })
      .addCase(deleteProfileTemplate.pending, state => {
        state.deleteProfileTemplateStatus = "loading";
      })
      .addCase(deleteProfileTemplate.fulfilled, (state, action) => {
        state.deleteProfileTemplateStatus = "succeeded";
        const { templateID } = action.payload;
        if (templateID) {
          profileTemplatesAdapter.removeOne(state, templateID);
        }
      })
      .addCase(deleteProfileTemplate.rejected, (state, action) => {
        state.deleteProfileTemplateStatus = "failed";
        state.error = action.payload;
      })
      .addCase(updateProfileTemplate.pending, state => {
        state.updateProfileTemplateStatus = "loading";
      })
      .addCase(updateProfileTemplate.fulfilled, (state, action) => {
        state.updateProfileTemplateStatus = "succeeded";
        const { templateID, callbackFunc, ...changes } = action.payload;
        profileTemplatesAdapter.updateOne(state, { id: templateID, changes });
      })
      .addCase(updateProfileTemplate.rejected, (state, action) => {
        state.updateProfileTemplateStatus = "failed";
        state.error = action.payload;
      });
  }
});

export const { resetProfileTemplates } = profileTemplatesSlice.actions;

export default profileTemplatesSlice.reducer;

export const selectCreateProfileTemplateStatus = state =>
  state.profileTemplates.createProfileTemplateStatus;
export const selectDeleteProfileTemplateStatus = state =>
  state.profileTemplates.deleteProfileTemplateStatus;
export const selectFetchProfileTemplateStatus = state =>
  state.profileTemplates.fetchProfileTemplateStatus;
export const selectUpdateProfileTemplateStatus = state =>
  state.profileTemplates.updateProfileTemplateStatus;

export const {
  selectAll: selectAllProfileTemplates,
  selectById: selectProfileTemplateById,
  selectIds: selectProfileTemplateIds
} = profileTemplatesAdapter.getSelectors(state => state.profileTemplates);
