import { axiosInstanceV2 } from "network/apis";

export const createProfileTemplateFunc = async params => {
  const { callbackFunc, ...props } = params;

  const response = await axiosInstanceV2.post("/core/profile_template/", props);

  callbackFunc();

  return { profileTemplate: response.data };
};

export const deleteProfileTemplateFunc = async params => {
  const { callbackFunc, templateID } = params;

  await axiosInstanceV2.delete(`/core/profile_template/${templateID}/`);

  callbackFunc();

  return { templateID };
};

export const updateProfileTemplateFunc = async params => {
  const { callbackFunc, templateID, ...changes } = params;

  await axiosInstanceV2.put(`/core/profile_template/${templateID}/`, changes);

  callbackFunc();

  return params;
};
