import React from "react";
import loadable from "@loadable/component";
import { Spinner } from "grommet";

export const Build = loadable(
  () => import("../containers/wireless/BuilderLayout/Build"),
  {
    fallback: <Spinner />,
  },
);
export const CoreBar = loadable(
  () => import("../containers/wireless/CoreBar"),
  {
    fallback: <Spinner />,
  },
);
export const Detail = loadable(
  () => import("containers/wireless/BuilderLayout/Players/detail"),
  { fallback: <Spinner /> },
);
export const Excludes = loadable(
  () => import("../containers/wireless/BuilderLayout/Excludes"),
  {
    fallback: <Spinner />,
  },
);
export const ResponsiveHome = loadable(
  () => import("../containers/ResponsiveElements/ResponsiveHome"),
  {
    fallback: <Spinner />,
  },
);
export const Locks = loadable(
  () => import("../containers/wireless/BuilderLayout/Locks"),
  {
    fallback: <Spinner />,
  },
);
export const PlayersPerTeam = loadable(
  () => import("../containers/wireless/BuilderLayout/PlayersPerTeam"),
  {
    fallback: <Spinner />,
  },
);
export const MobilePlayersPerGame = loadable(
  () => import("../containers/wireless/BuilderLayout/MobilePlayersPerGame"),
  {
    fallback: <Spinner />,
  },
);
export const ResponsiveLogin = loadable(
  () => import("../containers/ResponsiveElements/ResponsiveLogin"),
  {
    fallback: <Spinner />,
  },
);
export const ResponsiveResetPassword = loadable(
  () => import("../containers/ResponsiveElements/ResponsiveResetPassword"),
  {
    fallback: <Spinner />,
  },
);
export const ResponsiveResetPasswordConfirm = loadable(
  () =>
    import("../containers/ResponsiveElements/ResponsiveResetPasswordConfirm"),
  {
    fallback: <Spinner />,
  },
);
export const Main = loadable(() => import("containers/Main"), {
  fallback: <Spinner />,
});
export const NavBar = loadable(
  () => import("../containers/wireless/BuilderLayout/Navigator"),
  {
    fallback: <Spinner />,
  },
);
export const PastBuilds = loadable(
  () => import("../containers/wireless/BuilderLayout/PastBuilds"),
  { fallback: <Spinner /> },
);
export const Players = loadable(
  () => import("containers/wireless/BuilderLayout/Players/players"),
  { fallback: <Spinner /> },
);
export const PlayersDataTable = loadable(
  () => import("containers/wireless/BuilderLayout/Players/index"),
  { fallback: <Spinner /> },
);
export const PosStack = loadable(
  () => import("../containers/wireless/BuilderLayout/PosStack"),
  {
    fallback: <Spinner />,
  },
);
export const Preview = loadable(
  () => import("containers/wireless/BuilderLayout/Preview/index"),
  { fallback: <Spinner /> },
);
export const SavedLineups = loadable(
  () => import("../containers/wireless/BuilderLayout/SavedLineups"),
  { fallback: <Spinner /> },
);
export const Settings = loadable(
  () => import("../containers/wireless/BuilderLayout/Settings"),
  {
    fallback: <Spinner />,
  },
);
export const ResponsiveSignUp = loadable(
  () => import("containers/ResponsiveElements/ResponsiveSignUp"),
  {
    fallback: <Spinner />,
  },
);
export const Slate = loadable(
  () => import("../containers/wireless/BuilderLayout/Slate"),
  {
    fallback: <Spinner />,
  },
);
export const ResponsiveSubscribe = loadable(
  () => import("containers/ResponsiveElements/ResponsiveSubscribe"),
  {
    fallback: <Spinner />,
  },
);
export const TeamStack = loadable(
  () => import("../containers/wireless/BuilderLayout/TeamStack"),
  { fallback: <Spinner /> },
);
export const LateSwap = loadable(
  () => import("../containers/wireless/BuilderLayout/LateSwap"),
  {
    fallback: <Spinner />,
  },
);
export const ResponsiveBlogPage = loadable(
  () => import("../containers/ResponsiveElements/ResponsiveBlogPage"),
);
export const ResponsiveBlogPost = loadable(
  () => import("../containers/ResponsiveElements/ResponsiveBlogPost"),
);
export const ResponsiveAccountPage = loadable(
  () => import("../containers/ResponsiveElements/ResponsiveAccountPage"),
);
