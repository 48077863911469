import store from "store";
import { logout, refreshToken } from "store/userSlice";
import axios from "axios";

export const requestHandler = (request) => {
  const accessToken = store.getState().user.accessToken;
  if (
    !request.url.includes("/auth/social/") &&
    request.url !== "/auth/users/" &&
    accessToken !== "WP" &&
    !request.url.includes("/blog")
  ) {
    request.headers["Authorization"] = `JWT ${accessToken}`;
  }
  return request;
};

export const successHandler = (response) => {
  return response;
};

function handle403error(error) {
  // unable to refresh, redirect based on url
  if (window.location.href.includes("/subscribe")) {
    store.dispatch(logout());
    window.location = "/login";
  } else {
    window.location = "/subscribe";
  }
  return null;
}

async function waitWhileTokenWillBeRefreshed() {
  for (let i = 0; i < 30; i++) {
    await new Promise((resolve) => setTimeout(resolve, 300));
    const state = store.getState();
    const { refreshTokenStatus } = state?.user;
    if (["idle", "succeeded", "failed"].includes(refreshTokenStatus)) {
      break;
    }
  }
}

async function handleRefreshToken(error) {
  await store.dispatch(refreshToken());
  await waitWhileTokenWillBeRefreshed();

  let state = store.getState();
  const { accessToken } = state.user;

  let headers = error.config.headers;
  headers["Authorization"] = `JWT ${accessToken}`;

  // Create a new Axios instance and retry the request
  const instance = error.config.service || axios.create();
  return instance.request({
    ...error.config,
    _retry: true,
    headers,
  });
}

export const errorHandler = async (error) => {
  const originalRequest = error.config;
  if (error.message.includes("403")) {
    return handle403error();
  } else if (error.message.includes("401") && !originalRequest._retry) {
    try {
      return await handleRefreshToken(error);
    } catch (refreshError) {
      // unable to refresh, redirect based on url
      store.dispatch(logout());
      window.location = "/login";
    }
  }
  return Promise.reject({ ...error });
};
