import { axiosInstanceV2 } from "network/apis";

export const activeMySettingFunc = async params => {
  const { callbackFunc, mySettingID, slateID } = params;

  await axiosInstanceV2.post(`/core/my_setting/${mySettingID}/active/`, {
    slate_id: slateID
  });

  callbackFunc();

  return params;
};

export const createMySettingFunc = async params => {
  const { callbackFunc, ...props } = params;

  const response = await axiosInstanceV2.post("/core/my_setting/", props);

  callbackFunc();

  return { mySetting: response.data };
};

export const activateOrCreateMySettingFunc = async params => {
  const { callbackFunc, ...props } = params;

  const response = await axiosInstanceV2.post(
    "/core/my_setting/activate_or_create/",
    props
  );

  callbackFunc();

  return { mySetting: response.data };
};

export const deleteMySettingFunc = async params => {
  const { callbackFunc, mySettingID } = params;

  await axiosInstanceV2.delete(`/core/my_setting/${mySettingID}/`);

  callbackFunc();

  return { mySettingID };
};

export const updateMySettingFunc = async params => {
  const { callbackFunc, mySettingID, ...changes } = params;

  const response = await axiosInstanceV2.put(
    `/core/my_setting/${mySettingID}/`,
    changes
  );

  callbackFunc();

  return response.data;
};
