import { deepFreeze } from "grommet/utils";

const mobileDarkTheme = deepFreeze({
  global: {
    font: {
      size: "14px",
    },
    colors: {
      brand: "#0050b3",
      "background-contrast": "#0D1B1E",
      "background-back": "#283B42",
      "background-front": "#3b6d7c",
    },
  },
  notification: {
    toast: {
      time: 3000,
    },
  },
  tab: {
    active: {
      background: "neutral-3",
      color: "white",
    },
    border: undefined,
    pad: "xxsmall",
    margin: undefined,
  },
  table: {
    header: {
      background: {
        color: "light-2",
      },
      pad: "small",
    },
  },
});

export default mobileDarkTheme;
