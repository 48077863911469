import { axiosInstanceV1 } from "network/apis";

export const saveLineupFunc = async (params, { getState }) => {
  const { sports, roster } = params;

  const slatesState = getState().slates;
  const slateID = slatesState.slateID;

  const data = {
    slate_id: slateID,
    players_id: roster.players.map(p => p.id).join(""),
    roster
  };

  const response = await axiosInstanceV1.post(`/${sports}/my-favorite/`, {
    ...data
  });
  return response.data;
};

export const unSaveLineupFunc = async (params, { getState }) => {
  const { sports, roster } = params;

  const slatesState = getState().slates;
  const slateID = slatesState.slateID;

  const data = {
    slate_id: slateID,
    players_id: roster.players.map(p => p.id).join("")
  };

  await axiosInstanceV1.delete(`/${sports}/my-favorite/`, { data });
  return roster.players.map(p => p.id).join("");
};
