const assemble_draftkings_cs_classic = (players, assembleRules) => {
  const cptPlayers = players.map((p) => ({
    ...p,
    id: `${p.id}:CPT`,
    name: `${p.name} : CPT`,
    normalPosition: p.position,
    position: "CPT",
    pfp: parseFloat(p.pfp) * assembleRules["CPT"][0],
    salary: p.salary * assembleRules["CPT"][1],
  }));
  return cptPlayers.concat(players);
};

const assemble_fanduel_cs_classic = (players, assembleRules) => {
  const mvpPlayers = players.map((p) => ({
    ...p,
    id: `${p.id}:MVP`,
    name: `${p.name} : MVP`,
    normalPosition: p.position,
    position: "MVP",
    pfp: parseFloat(p.pfp) * assembleRules["MVP"][0],
    salary: p.salary * assembleRules["MVP"][1],
  }));
  return mvpPlayers.concat(players);
};

const assemble_draftkings_lol_classic = (players, assembleRules) => {
  const cptPlayers = players.map((p) => ({
    ...p,
    id: `${p.id}:CPT`,
    name: `${p.name} : CPT`,
    normalPosition: p.position,
    position: "CPT",
    pfp: parseFloat(p.pfp) * assembleRules["CPT"][0],
    salary: p.salary * assembleRules["CPT"][1],
  }));
  return cptPlayers.concat(players);
};

const assemble_fanduel_lol_classic = (players, assembleRules) => {
  const starPlayers = players.map((p) => ({
    ...p,
    id: `${p.id}:STAR`,
    name: `${p.name} : STAR`,
    normalPosition: p.position,
    position: "STAR",
    pfp: parseFloat(p.pfp) * assembleRules["STAR"][0],
    salary: p.salary * assembleRules["STAR"][1],
  }));
  return starPlayers.concat(players);
};

const assemble_draftkings_mma_single = (players, assembleRules) => {
  const cptPlayers = players.map((p) => ({
    ...p,
    id: `${p.id}:CPT`,
    name: `${p.name} : CPT`,
    normalPosition: p.position,
    position: "CPT",
    pfp: parseFloat(p.pfp) * assembleRules["CPT"][0],
    salary: p.salary * assembleRules["CPT"][1],
  }));
  return cptPlayers.concat(players);
};

const assemble_fanduel_mma_classic = (players, assembleRules) => {
  const mvpPlayers = players.map((p) => ({
    ...p,
    id: `${p.id}:MVP`,
    name: `${p.name} : MVP`,
    normalPosition: p.position,
    position: "MVP",
    pfp: parseFloat(p.pfp) * assembleRules["MVP"][0],
    salary: p.salary * assembleRules["MVP"][1],
  }));
  return mvpPlayers.concat(players);
};

const assemble_fanduel_nfl_single = (players, assembleRules) => {
  const mvpPlayers = players.map((p) => ({
    ...p,
    id: `${p.id}:MVP`,
    name: `${p.name} : MVP`,
    normalPosition: p.position,
    position: "MVP",
    pfp: parseFloat(p.pfp) * assembleRules["MVP"][0],
    salary: p.salary * assembleRules["MVP"][1],
  }));
  const anyFlexPlayers = players
    .filter((p) => p.position !== "DEF")
    .map((p) => ({
      ...p,
      id: `${p.id}:AnyFLEX`,
      name: `${p.name} : AnyFLEX`,
      normalPosition: p.position,
      position: "AnyFLEX",
      pfp: parseFloat(p.pfp) * assembleRules["AnyFLEX"][0],
      salary: p.salary * assembleRules["AnyFLEX"][1],
    }));
  return mvpPlayers.concat(anyFlexPlayers);
};

const assemble_nftdraft_nfl = (players) => {
  const allPlayers = [];
  players.map((p) => {
    if (p.nftdraft_salary) {
      Object.entries(p.nftdraft_salary).map(([season, salary]) => {
        const entry = {
          ...p,
          id: `${p.id}:${season}`,
          name: `${p.name} : ${season}`,
          normalPosition: p.position,
          position: p.position,
          pfp: parseFloat(p.pfp),
          salary: parseInt(salary),
        };
        allPlayers.push(entry);
      });
    }
  });
  return allPlayers;
};

const assemble_fanduel_soccer_single = (players) => {
  const cptPlayers = players
    .filter((p) => ["FWD", "MID"].includes(p.position))
    .map((p) => ({
      ...p,
      id: `${p.id}:CPT`,
      name: `${p.name} : CPT`,
      normalPosition: p.position,
      position: "CPT",
      pfp: parseFloat(p.pfp) * 1.5,
    }));
  return players.concat(cptPlayers);
};

const assemble_draftkings_f1 = (players) => {
  const cptPlayers = players
    .filter((p) => ["D"].includes(p.position))
    .map((p) => ({
      ...p,
      id: `${p.id}:CPT`,
      name: `${p.name} : CPT`,
      normalPosition: p.position,
      position: "CPT",
      pfp: parseFloat(p.pfp) * 1.5,
      salary: parseFloat(p.salary) * 1.5,
    }));
  return players.concat(cptPlayers);
};

const assemble_common_single = (players, assembleRules) => {
  const positionNames = Object.keys(assembleRules);
  return players.flatMap((p) =>
    positionNames.map((positionName) => ({
      ...p,
      id: `${p.id}:${positionName}`,
      name: `${p.name} : ${positionName}`,
      normalPosition: p.position,
      position: positionName,
      pfp: parseFloat(p.pfp) * assembleRules[positionName][0],
      salary: p.salary * assembleRules[positionName][1],
    })),
  );
};

const assemble_champion_mode = (players, assembleRules) => {
  const positionNames = Object.keys(assembleRules);
  return players.flatMap((p) =>
    positionNames.map((positionName) => ({
      ...p,
      id: `${p.id}:${positionName}`,
      name: `${p.name} : ${positionName}`,
      normalPosition: p.position,
      position: positionName,
      pfp: parseFloat(p.pfp) * assembleRules[positionName][0],
      salary: 1,
    })),
  );
};

const assemble_multiplier = (players) => {
  return players.map((p) => ({
    ...p,
    salary: 1,
    multiplier_projection:
      Math.round(
        (parseFloat(p.my_projection ? p.my_projection : p.pfp) *
          parseFloat(p.multiplier) +
          Number.EPSILON) *
          100,
      ) / 100,
  }));
};

const assemble_draftkings_nhl = (players) => {
  return players.map((player) => {
    if (player.position.includes("W")) {
      player.position = "W";
    }
    return player;
  });
};

const assemble_yahoo_nhl = (players) => {
  return players.map((player) => {
    if (player.position.includes("W")) {
      player.position = "W";
    }
    return player;
  });
};

const assemble_superdraft_mlb = (players) => {
  return players.map((player) => {
    if (player.position !== "SP") {
      player.position = "H";
    } else {
      player.position = "P";
    }
    player.salary = 1;
    return player;
  });
};

export const assemble = (players, slate, sports) => {
  const assembleRules = slate.assemble_rules;

  if (sports === "cs") {
    if (slate.variety === "classic" && slate.site === "fanduel") {
      return assemble_fanduel_cs_classic(players, assembleRules);
    }
    if (slate.variety === "classic" && slate.site === "draftkings") {
      return assemble_draftkings_cs_classic(players, assembleRules);
    }
  }

  if (sports === "lol") {
    if (slate.variety === "classic" && slate.site === "fanduel") {
      return assemble_fanduel_lol_classic(players, assembleRules);
    }
    if (slate.variety === "classic" && slate.site === "draftkings") {
      return assemble_draftkings_lol_classic(players, assembleRules);
    }
  }

  if (sports === "mma") {
    if (slate.variety === "classic" && slate.site === "fanduel") {
      return assemble_fanduel_mma_classic(players, assembleRules);
    }
    if (slate.variety === "single game" && slate.site === "fanduel") {
      return assemble_fanduel_mma_classic(players, assembleRules);
    }
    if (slate.variety === "single game" && slate.site === "draftkings") {
      return assemble_draftkings_mma_single(players, assembleRules);
    }
  }

  if (sports === "nfl") {
    if (slate.variety === "single game" && slate.site === "fanduel") {
      return assemble_fanduel_nfl_single(players, assembleRules);
    }
    if (slate.variety === "classic" && slate.site === "nftdraft") {
      return assemble_nftdraft_nfl(players);
    }
  }

  if (sports === "soccer") {
    const adjustPlayers = players.map((p) => ({
      ...p,
      pfp: p.starting_position ? parseFloat(p.pfp) : 0,
    }));
    if (slate.variety === "single game" && slate.site === "fanduel") {
      return assemble_fanduel_soccer_single(adjustPlayers);
    }
    if (slate.variety === "single game" && slate.site === "draftkings") {
      return assemble_common_single(adjustPlayers, assembleRules);
    }
    return adjustPlayers;
  }

  if (sports === "nhl") {
    if (slate.site === "draftkings") {
      return assemble_draftkings_nhl(players);
    }
    if (slate.site === "yahoo") {
      if (slate.variety !== "single game") {
        return assemble_yahoo_nhl(players);
      } else {
        players = assemble_yahoo_nhl(players);
      }
    }
  }

  if (sports === "mlb") {
    if (slate.site === "superdraft" && slate.variety === "multiplier") {
      return assemble_multiplier(assemble_superdraft_mlb(players));
    }
  }

  if (sports === "f1") {
    if (slate.site === "draftkings") {
      return assemble_draftkings_f1(players);
    }
  }

  if (slate.variety === "multiplier") {
    return assemble_multiplier(players);
  }

  if (["champion_mode", "super_champ_mode"].includes(slate.variety)) {
    return assemble_multiplier(assemble_champion_mode(players, assembleRules));
  }

  if (assembleRules) {
    return assemble_common_single(players, assembleRules);
  }

  return players;
};
