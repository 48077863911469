import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { axiosInstanceV1, axiosInstanceV2 } from "network/apis";
import { assemble } from "store/assemblePlayers";
import {
  updateMLBData,
  updateNHLData,
  updateSoccerData,
} from "store/updatePlayersBySport";

const playersAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.pfp - a.pfp,
});

export const initialState = playersAdapter.getInitialState({
  nextPage: 1,
  status: "idle",
  playerDataStatus: "idle",
  corePlayerDataStatus: "idle",
  nftDraftStatus: "idle",
  error: null,
  playerDataError: null,
  nftDraftError: null,
  playerData: [],
  nftDraftPlayers: [],
  ndtDraftUseOnlyUserCards: false,
  corePlayers: {},
});

export const fetchPlayers = createAsyncThunk(
  "players/fetchPlayers",
  async ({ sports, slateID, page }, { getState }) => {
    const slatesState = getState().slates;
    const slate = slatesState.entities[slateID];
    const response = await axiosInstanceV2.get(`/${sports}/players/`, {
      params: { slate_id: slateID, page },
    });
    const { results: players, next_page: nextPage } = response.data;
    return { nextPage, players: assemble(players, slate, sports) };
  },
);

export const fetchCorePlayers = createAsyncThunk(
  "players/fetchCorePlayers",
  async ({ sports, slateID }) => {
    const response = await axiosInstanceV1.get(`/misc/core-player/`, {
      params: { slate_id: slateID },
    });
    return response.data;
  },
);

export const addCorePlayers = createAsyncThunk(
  "players/addCorePlayers",
  async ({ slateID, sport, adminCoreProfile, players }) => {
    const data = {
      slate: slateID,
      adminCorePlayer: adminCoreProfile,
      league: sport,
      players: players,
    };
    await axiosInstanceV1.post("/misc/core-player/add_to/", data);
    return { players, adminCoreProfile };
  },
);

export const removeCorePlayers = createAsyncThunk(
  "players/removeCorePlayers",
  async ({ slateID, sport, adminCoreProfile, players }) => {
    const data = {
      slate: slateID,
      adminCorePlayer: adminCoreProfile,
      league: sport,
      players: players,
    };
    await axiosInstanceV1.post("/misc/core-player/remove_from/", data);
    return { players, adminCoreProfile };
  },
);

export const fetchPlayerData = createAsyncThunk(
  "players/fetchPlayerData",
  async ({ sports, playerId }) => {
    const response = await axiosInstanceV1.get(`/${sports}/player-history/`, {
      params: { history_player_id: playerId },
    });
    return response.data || [];
  },
);

export const fetchNftDraftPlayers = createAsyncThunk(
  "players/fetchNftDraftPlayers",
  async ({ userId }) => {
    const response = await axiosInstanceV2.get(
      `/nfl/nftdraft-players/${userId}`,
      {
        params: {},
      },
    );
    return {
      players: response?.data?.players || [],
      useOnlyCards: response.data.use_only_cards,
    };
  },
);

export const updateNftDraftPlayers = createAsyncThunk(
  "players/updateNftDraftPlayers",
  async (params) => {
    const { callbackFunc, userId, ...args } = params;

    const response = await axiosInstanceV2.patch(
      `/nfl/nftdraft-players/${userId}/`,
      { ...args, user_id: userId },
    );

    callbackFunc();

    return {
      players: response.data.players,
      useOnlyCards: response.data.use_only_cards,
    };
  },
);

const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    resetPlayers(state) {
      state.nextPage = 1;
      state.error = null;
      state.nftDraftStatus = "idle";
      state.nftDraftError = null;
      state.nftDraftPlayers = [];
      playersAdapter.removeAll(state);
    },
    resetPlayerData(state) {
      state.playerData = [];
      state.error = null;
      state.playerDataStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { players, nextPage } = action.payload;
        playersAdapter.upsertMany(state, players);
        state.nextPage = nextPage;
      })
      .addCase(fetchPlayers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPlayerData.pending, (state) => {
        state.playerDataStatus = "loading";
      })
      .addCase(fetchPlayerData.fulfilled, (state, action) => {
        state.playerDataStatus = "succeeded";
        state.playerData = action.payload;
      })
      .addCase(fetchPlayerData.rejected, (state, action) => {
        state.playerDataStatus = "failed";
        state.playerDataError = action.payload;
      })
      .addCase(fetchNftDraftPlayers.pending, (state) => {
        state.nftDraftStatus = "loading";
      })
      .addCase(fetchNftDraftPlayers.fulfilled, (state, action) => {
        state.nftDraftStatus = "succeeded";
        const { players, useOnlyCards } = action.payload;
        state.nftDraftPlayers = players;
        state.ndtDraftUseOnlyUserCards = useOnlyCards;
      })
      .addCase(fetchNftDraftPlayers.rejected, (state, action) => {
        state.nftDraftStatus = "failed";
        state.nftDraftError = action.payload;
      })
      .addCase(updateNftDraftPlayers.pending, (state) => {
        state.nftDraftStatus = "loading";
      })
      .addCase(updateNftDraftPlayers.fulfilled, (state, action) => {
        const { players, useOnlyCards } = action.payload;
        state.nftDraftStatus = "succeeded";
        state.nftDraftPlayers = players;
        state.ndtDraftUseOnlyUserCards = useOnlyCards;
      })
      .addCase(updateNftDraftPlayers.rejected, (state, action) => {
        state.nftDraftStatus = "failed";
        state.nftDraftError = action.payload;
      })
      .addCase(fetchCorePlayers.pending, (state) => {
        state.corePlayerDataStatus = "loading";
      })
      .addCase(fetchCorePlayers.fulfilled, (state, action) => {
        state.corePlayerDataStatus = "succeeded";
        let result = {};
        /**
         * Represents a player.
         * @typedef {Object} Player
         * @property {string} slate_id - The id of the slate.
         * @property {string} core_play - The core play of admin.
         * @property {string} player_name - The name of the player.
         */

        /**
         * An array of players.
         * @type {Player[]}
         */
        const data = action.payload;
        // iterate over each element in the data
        for (let item of data) {
          let key = item.core_play;

          // if this key hasn't been seen before, create a new array for it
          if (!result[key]) {
            result[key] = [];
          }

          // add the player_name to the array if it doesn't already exist
          if (!result[key].includes(item.player_name)) {
            result[key].push(item.player_name);
          }
        }
        state.corePlayers = result;
      })
      .addCase(fetchCorePlayers.rejected, (state, action) => {
        state.corePlayerDataStatus = "failed";
        state.playerDataError = action.payload;
      })
      .addCase(addCorePlayers.pending, (state) => {
        state.corePlayerDataStatus = "loading";
      })
      .addCase(addCorePlayers.fulfilled, (state, action) => {
        const { players, adminCoreProfile } = action.payload;
        state.corePlayers = {
          ...state.corePlayers,
          [adminCoreProfile]: [
            ...(state.corePlayers[adminCoreProfile] || []),
            ...players,
          ],
        };
      })
      .addCase(addCorePlayers.rejected, (state, action) => {
        state.corePlayerDataStatus = "failed";
        state.error = action.payload;
      })
      .addCase(removeCorePlayers.pending, (state) => {
        state.corePlayerDataStatus = "loading";
      })
      .addCase(removeCorePlayers.fulfilled, (state, action) => {
        const { players, adminCoreProfile } = action.payload;
        state.corePlayers = {
          ...state.corePlayers,
          [adminCoreProfile]: state.corePlayers[adminCoreProfile].filter(
            (player) => !players.includes(player),
          ),
        };
      })
      .addCase(removeCorePlayers.rejected, (state, action) => {
        state.corePlayerDataStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetPlayers, resetPlayerData } = playersSlice.actions;

export default playersSlice.reducer;

export const selectFetchPlayersStatus = (state) => state.players.status;

export const selectPlayersByIDs = (ids) =>
  createSelector(
    (state) => state.players.entities,
    (entities) => {
      return ids.reduce(function (o, k) {
        o[k] = entities[k] || entities[k.split(":")[0]];
        return o;
      }, {});
    },
  );

export const {
  selectAll: selectAllPlayers,
  selectById: selectPlayerById,
  selectIds: selectPlayerIds,
} = playersAdapter.getSelectors((state) => state.players);

export const selectAllPositions = createSelector(
  [selectAllPlayers],
  (players) => {
    return players.reduce((acc, item) => {
      for (const pos of item.position.split("/")) {
        if (!acc.includes(pos)) {
          acc.push(pos);
        }
      }
      return acc;
    }, []);
  },
);

export const selectAllNormalPositions = createSelector(
  [selectAllPlayers],
  (players) => {
    return players.reduce((acc, item) => {
      for (const pos of (item.normalPosition || item.position).split("/")) {
        if (!acc.includes(pos)) {
          acc.push(pos);
        }
      }
      return acc;
    }, []);
  },
);

export const selectAllVerbosePlayers = createSelector(
  [
    selectAllPlayers,
    (state) => state.slates.slateID,
    (state) => state.slates.excluded,
    (state) => state.slates.locked,
    (state) => state.mySettings.entities,
    (state) => state.slates.processorWebsocketMessages,
    (state) => state.slates.entities,
  ],
  (
    players,
    slateID,
    excluded,
    locked,
    mySettingsEntities,
    wsData,
    slatesEntities,
  ) => {
    const sports = (slatesEntities[slateID] || {}).sports;
    let myData = {
      myProj: {},
      myRand: {},
      myMin: {},
      myMax: {},
      myPo: {},
      myLine: {},
      myPPLine: {},
      myBO: {},
    };
    const mySettings = Object.values(mySettingsEntities || {});
    const activeMySettings = mySettings.find((s) => s.is_active);
    if (activeMySettings) {
      myData = activeMySettings.custom_data || {
        myProj: {},
        myRand: {},
        myMin: {},
        myMax: {},
        myPo: {},
        myLine: {},
        myPPLine: {},
        myBO: {},
      };
    }
    let excludedIDs = [];
    let lockedIDs = [];
    if (slateID) {
      excludedIDs = excluded[slateID] || [];
      lockedIDs = locked[slateID] || [];
      lockedIDs = lockedIDs.reduce((acc, id) => {
        return { ...acc, [id.split("|")[0]]: id.split("|")[1] };
      }, {});
    }

    let data = players.map((p) => ({
      ...p,
      excluded: excludedIDs.includes(p.id),
      pfp: wsData?.[p.name]?.pfp?.pfp || p.pfp,
      po: wsData?.[p.name]?.po?.po || p.po,
      locked: Object.keys(lockedIDs).includes(p.id),
      locked_on_position: lockedIDs[p.id],
      max: myData.myMax[p.name] || null,
      min: myData.myMin[p.name] || null,
      my_po: myData.myPo[p.name] || null,
      my_projection: myData.myProj[p.name] || null,
      randomness: myData.myRand[p.name] || null,
    }));

    if (sports === "nhl") {
      data = updateNHLData(data, myData, wsData);
    }

    if (sports === "mlb") {
      data = updateMLBData(data, myData, wsData);
    }

    if (sports === "soccer") {
      data = updateSoccerData(data, wsData);
    }

    return data;
  },
);

export const selectAllExcludedPlayers = createSelector(
  [
    selectAllPlayers,
    (state) => state.slates.slateID,
    (state) => state.slates.excluded,
  ],
  (players, slateID, excluded) => {
    let excludedIDs = [];
    if (slateID) {
      excludedIDs = excluded[slateID] || [];
    }
    return players.filter((p) => excludedIDs.includes(p.id));
  },
);

export const selectAllLockedPlayers = createSelector(
  [
    selectAllPlayers,
    (state) => state.slates.slateID,
    (state) => state.slates.locked,
  ],
  (players, slateID, locked) => {
    let lockedIDs = [];
    if (slateID) {
      lockedIDs = locked[slateID] || [];
    }
    return players.filter((p) => lockedIDs.includes(p.id));
  },
);

export const selectBacktestingPresented = createSelector(
  [selectAllPlayers],
  (players) => players.some((p) => p.points),
);

export const selectCeilPresented = createSelector(
  [selectAllPlayers],
  (players) => players.some((p) => p.ceil),
);

export const selectFloorPresented = createSelector(
  [selectAllPlayers],
  (players) => players.some((p) => p.floor),
);

export const selectPlayerData = (state) => {
  return state.players.playerData || [];
};

export const selectNftDraftPlayers = (state) => state.players.nftDraftPlayers;
export const selectFetchNflDraftPlayersStatus = (state) =>
  state.players.nftDraftStatus;
export const selectUseOnlyNftDraftPlayers = (state) =>
  state.players.ndtDraftUseOnlyUserCards;

export const selectCorePlayers = (state) => state.players.corePlayers;

export const selectOnlyNftDraftCardsPlayers = createSelector(
  [selectAllPlayers, selectNftDraftPlayers],
  (players, cards) => {
    const cardsNames = cards.map((c) => `${c.name}-${c.season}`);
    return players.filter((p) =>
      cardsNames.includes(`${p.site_id}-${p.id.split(":")[1]}`),
    );
  },
);
