import store from "store";

const Auth = {
  isAuth() {
    const cookieName = "wordpress_test_cookie";
    const match = document.cookie.match(
      new RegExp("(^| )" + cookieName + "=([^;]+)")
    );
    if (match) {
      return "WP";
    }
    return store.getState().user.accessToken;
  }
};

export default Auth;
