export const excludePlayer = (params, { dispatch }) => {
  const { playerID } = params;

  setTimeout(() => {
    dispatch({ type: "slates/resetExcludeErrorAndStatus" });
  }, 3000);

  return playerID;
};

export const unExcludePlayer = (params, { dispatch }) => {
  const { playerID } = params;

  setTimeout(() => {
    dispatch({ type: "slates/resetExcludeErrorAndStatus" });
  }, 3000);

  return playerID;
};
