import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { axiosInstanceV2 } from "network/apis";

/**
 * @typedef {Object} BlogPost
 *
 * @property {number} post_id - The unique identifier of the post.
 * @property {string} date - The publication date-time of the post in local timezone.
 * @property {string} date_gmt - The publication date-time of the post in GMT timezone.
 * @property {string} guid - The globally unique identifier for the post.
 * @property {string} modified - The date-time of the last modification to the post in the local timezone.
 * @property {string} modified_gmt - The date-time of the last modification to the post in GMT timezone.
 * @property {string} slug - The post's slug.
 * @property {string} status - The status of the post (e.g. "publish").
 * @property {string} post_type - The type of the post (e.g. "post").
 * @property {string} link - The link to the post.
 * @property {string} title - The title of the post.
 * @property {string} content - The content of the post, HTML formatted.
 * @property {string} excerpt - The short excerpt of the post, HTML formatted.
 * @property {number} author - The unique identifier of the post's author.
 * @property {number} featured_media - The unique identifier of the media featured in the post.
 * @property {string} comment_status - The status for comments (e.g "open").
 * @property {string} ping_status - The status for pings (e.g "open").
 * @property {boolean} sticky - Whether the post is marked as sticky.
 * @property {string} template - The template used for the post.
 */
``;

const blogPostsAdapter = createEntityAdapter({
  selectId: item => item.post_id
});

const initialState = blogPostsAdapter.getInitialState({
  status: "idle",
  error: null,
  totalPages: 0,
  currentPage: 1,
  next: null,
  previous: null
});

export const fetchBlogPosts = createAsyncThunk(
  "blogPosts/fetchBlogPosts",
  async (page = 1, { getState }) => {
    const response = await axiosInstanceV2.get(`/blog/posts/?page=${page}`);
    return response.data;
  }
);

const blogPostsSlice = createSlice({
  name: "blogPosts",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBlogPosts.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchBlogPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { count, next, previous, results } = action.payload;
        state.totalPages = Math.ceil(count / 12);
        state.next = next;
        state.previous = previous;
        let currentPage = 1;
        if (next) {
          currentPage = new URL(next).searchParams.get("page");
          state.currentPage = currentPage ? parseInt(currentPage) - 1 : 1;
        } else {
          state.currentPage = 1;
        }
        // fixme this logic for local debug
        if (process.env.NODE_ENV === "development") {
          results.forEach(r =>
            r.images.forEach(
              i => (i.image = i.image.replace("backend:8000", "localhost:8000"))
            )
          );
        }
        blogPostsAdapter.upsertMany(state, results);
      })
      .addCase(fetchBlogPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  }
});

export default blogPostsSlice.reducer;

export const {
  selectAll: selectAllBlogPosts,
  selectById: selectBlogPostById,
  selectIds: selectBlogPostIds
} = blogPostsAdapter.getSelectors(state => state.blogPosts);

export const selectBlogPostsFetchStatus = state => state.blogPosts.status;
export const selectBlogPostsTotalPages = state => state.blogPosts.totalPages;
export const selectBlogPostsCurrentPage = state => state.blogPosts.currentPage;
