import axios from "axios";
import { requestHandler, successHandler, errorHandler } from "../interceptors";

const { BASE_URL_V1 } = process.env;
const { BASE_URL_V2 } = process.env;

export const axiosInstanceV1 = axios.create({
  baseURL: BASE_URL_V1,
  headers: {
    "Content-Type": "application/json"
  }
});

axiosInstanceV1.interceptors.request.use(request => requestHandler(request));

axiosInstanceV1.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export const axiosInstanceV2 = axios.create({
  baseURL: BASE_URL_V2,
  headers: {
    "Content-Type": "application/json"
  }
});

axiosInstanceV2.interceptors.request.use(request => requestHandler(request));

axiosInstanceV2.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export const axiosFormInstanceV1 = axios.create({
  baseURL: BASE_URL_V1,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
});
