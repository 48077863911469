import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstanceV1, axiosInstanceV2 } from "network/apis";
import { ValidationError } from "utils/Shared";

export const initialState = {
  status: "idle",
  refreshTokenStatus: "idle",
  error: null,
  accessToken: null,
  refreshToken: null,
  profile: {
    email: null,
    id: null,
    username: null,
    profile_status: null,
    is_staff: null,
    is_trial: null,
  },
  stripeSubscriptions: null,
  paypalSubscriptions: null,
  coreProfiles: null,
  useDarkTheme: false,
};

export const login = createAsyncThunk(
  "user/login",
  async ({ username, password, callbackFunction = () => {} }) => {
    const response = await axiosInstanceV1.post(`/auth/jwt/create/`, {
      username,
      password,
    });
    return response.data;
  },
);

let refreshPending = false;

export const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async (_, { getState, rejectWithValue }) => {
    // Notice addition of rejectWithValue
    const { refreshToken } = getState().user;
    if (refreshPending) {
      return null;
    }
    try {
      refreshPending = true;
      const response = await axiosInstanceV1.post("/auth/jwt/refresh/", {
        refresh: refreshToken,
      });
      return {
        accessToken: response.data.access,
      };
    } catch (err) {
      return rejectWithValue(err.response);
    }
  },
);

export const fetchProfile = createAsyncThunk("user/profile", async () => {
  const response = await axiosInstanceV1.get("/auth/users/me/");
  return response.data;
});

export const signUp = createAsyncThunk(
  "user/signUp",
  async ({ values, setFieldError }) => {
    const { email, password } = values;
    const validationResponse = await axiosInstanceV1.post(
      "/auth/validate-duplicate/",
      { email },
    );
    let errorMessage = "";
    if (validationResponse.data !== "") {
      errorMessage =
        "Your username/password have some problem, probably it's exists";
      setFieldError("email", errorMessage);
      throw new ValidationError(errorMessage);
    }

    await axiosInstanceV1
      .post("/auth/users/", {
        email,
        username: email,
        password,
      })
      .catch(async (reason) => {
        const { response: signupResponse } = reason;
        console.log(reason);
        if (signupResponse.status !== 200) {
          if (signupResponse.status === 400) {
            errorMessage = JSON.stringify(signupResponse.data);
          } else {
            errorMessage = "Can't create account with this data";
          }
          setFieldError("email", errorMessage);
          throw ValidationError(errorMessage);
        }
      });

    const response = await axiosInstanceV1.post(`/auth/jwt/create/`, {
      username: email,
      password,
    });
    return response.data;
  },
);

export const fetchStripeSubscription = createAsyncThunk(
  "user/fetchStripeSubscription",
  async () => {
    const response = await axiosInstanceV1.get("/stripe/subscription/");
    return response.data;
  },
);

export const cancelStripeSubscription = createAsyncThunk(
  "user/cancelStripeSubscription",
  async ({ subscriptionID }) => {
    await axiosInstanceV1.delete(`/stripe/subscription/`, {
      data: { subscriptionID },
    });

    const response = await axiosInstanceV1.get("/stripe/subscription/");
    return response.data;
  },
);

export const cancelPaypalSubscription = createAsyncThunk(
  "user/cancelPaypalSubscription",
  async () => {
    await axiosInstanceV1.delete(`/paypal/subscription/`);

    const response = await axiosInstanceV1.get("/paypal/subscription/");
    return response.data;
  },
);

export const updateStripeSubscription = createAsyncThunk(
  "user/updateStripeSubscription",
  async ({ plan, subscriptionID }) => {
    await axiosInstanceV1.post(`/stripe/customer_plan/`, {
      plan,
      subscriptionID,
    });

    const response = await axiosInstanceV1.get("/stripe/subscription/");
    return response.data;
  },
);

export const updatePaypalSubscription = createAsyncThunk(
  "user/updatePaypalSubscription",
  async ({ planID, subscriptionID }) => {
    await axiosInstanceV1.post(`/paypal/customer_plan/`, {
      plan_id: planID,
      subscription_id: subscriptionID,
    });

    const response = await axiosInstanceV1.get("/paypal/subscription/");
    return response.data;
  },
);

export const getStripeSubscriptions = createAsyncThunk(
  "user/getStripeSubscriptions",
  async () => {
    const response = await axiosInstanceV2.get(
      "/stripe/get-stripe-subscriptions",
    );
    return response.data;
  },
);

export const getPayPalSubscriptions = createAsyncThunk(
  "user/getPayPalSubscriptions",
  async () => {
    const response = await axiosInstanceV2.get("paypal/subscriptions");
    return response.data;
  },
);

export const fetchCoreProfiles = createAsyncThunk(
  "user/fetchCoreProfiles",
  async ({ sports, site }) => {
    const response = await axiosInstanceV1.get("misc/core-play/admin/", {
      params: { league: sports, site },
    });
    return response.data;
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.profile = {};
    },
    changeTheme(state) {
      state.useDarkTheme = !state.useDarkTheme;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { access: accessToken, refresh: refreshToken } = action.payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(signUp.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { access: accessToken, refresh: refreshToken } = action.payload;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
      })
      .addCase(refreshToken.pending, (state) => {
        state.refreshTokenStatus = "loading";
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        if (action.payload) {
          refreshPending = false;
          state.refreshTokenStatus = "succeeded";
          state.accessToken = action.payload.accessToken;
        }
      })
      .addCase(refreshToken.rejected, (state, action) => {
        refreshPending = false;
        state.refreshTokenStatus = "failed";
        state.accessToken = null;
        state.refreshToken = null;
        window.location = "/login";
      })
      .addCase(signUp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchStripeSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStripeSubscription.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { subscriptions } = action.payload;
        state.stripeSubscriptions = subscriptions;
      })
      .addCase(fetchStripeSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getPayPalSubscriptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPayPalSubscriptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { results } = action.payload;
        state.paypalSubscriptions = results;
      })
      .addCase(getPayPalSubscriptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.paypalSubscriptions = null;
      })
      .addCase(cancelStripeSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cancelStripeSubscription.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { subscriptions } = action.payload;
        state.stripeSubscriptions = subscriptions;
      })
      .addCase(cancelStripeSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(cancelPaypalSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(cancelPaypalSubscription.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.paypalSubscriptions = action.payload;
      })
      .addCase(cancelPaypalSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateStripeSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStripeSubscription.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { subscriptions } = action.payload;
        state.stripeSubscriptions = subscriptions;
      })
      .addCase(updateStripeSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updatePaypalSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePaypalSubscription.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.paypalSubscriptions = action.payload;
      })
      .addCase(updatePaypalSubscription.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // v2 subscriptions
      .addCase(getStripeSubscriptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStripeSubscriptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { valid_subscriptions } = action.payload;
        state.stripeSubscriptions = valid_subscriptions;
      })
      .addCase(getStripeSubscriptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.stripeSubscriptions = null;
      })
      .addCase(fetchCoreProfiles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCoreProfiles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.coreProfiles = action.payload;
      })
      .addCase(fetchCoreProfiles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { logout, changeTheme } = userSlice.actions;

export default userSlice.reducer;

export const selectAccessToken = (state) => state.user.accessToken;
export const selectAuthStatus = (state) => state.user.status;
export const selectUsername = (state) =>
  state.user.profile.email || state.user.profile.username;
export const selectName = (state) => {
  const username = state.user.profile.email || state.user.profile.username;
  if (username) {
    return username.split("@")[0];
  }
  return username;
};
export const selectUserId = (state) => state.user.profile.id;
export const selectUseDarkTheme = (state) => state.user.useDarkTheme;

export const selectStripeSubscription = (state) =>
  state.user.stripeSubscriptions;
export const selectPaypalSubscription = (state) =>
  state.user.paypalSubscriptions;
export const selectIsStaff = (state) => state.user.profile.is_staff;
export const selectUserProfileStatus = (state) =>
  state.user.profile.profile_status;

/**
 * Determines if a Stripe subscription has not ended based on the current period end date.
 *
 * @param {StripeSubscription} subscription - The StripeSubscription object.
 * @returns {boolean} - Returns `true` if the current period end date is in the future, otherwise `false`.
 */
function stripeSubscriptionNotEnded(subscription) {
  try {
    const currentPeriodEnd = new Date(subscription?.current_period_end);
    const now = new Date();
    if (currentPeriodEnd > now) {
      return true;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}

/**
 * Selector function to check if the user is subscribed.
 *
 * @param {Object} state - The Redux state from which user details are to be extracted.
 * @param {UserInitialState} state.user - The current user state.
 * @returns {boolean} - Returns `true` if the user is subscribed (either they're staff, or they have active Stripe/Paypay subscriptions, or they're in trial), otherwise `false`.
 */
export const selectUserSubscribed = (state) => {
  if (state.user.profile.is_staff) {
    return true;
  }
  return (
    !!(
      state.user.stripeSubscriptions &&
      state.user.stripeSubscriptions.length > 0 &&
      state.user.stripeSubscriptions.some(
        (s) => stripeSubscriptionNotEnded(s) || s.status === "active",
      )
    ) ||
    !!(
      state.user.paypalSubscriptions && state.user.paypalSubscriptions.length
    ) ||
    state.user.profile.is_trial
  );
};

export const selectCoreProfiles = (state) => state.user.coreProfiles;
