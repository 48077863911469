import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { axiosInstanceV2 } from "network/apis";
import { selectAllProfileTemplates } from "store/profileTemplatesSlice";
import { selectAllMySettings } from "store/mySettingsSlice";

const multiBuildSettingsAdapter = createEntityAdapter({});

export const initialState = multiBuildSettingsAdapter.getInitialState({
  status: "idle",
  error: null
});

export const fetchMultiBuildSettings = createAsyncThunk(
  "multiBuildSettings/fetchMultiBuildSettings",
  async ({ slateId, sports, site }) => {
    const response = await axiosInstanceV2.get("/core/multi-build-setting/", {
      params: { slate_id: slateId, sports, site }
    });
    return response.data;
  }
);

export const updateMultiBuildSettings = createAsyncThunk(
  "multiBuildSettings/updateMultiBuildSettings",
  async ({ id, ...data }) => {
    await axiosInstanceV2.patch(`/core/multi-build-setting/${id}/`, {
      ...data,
      id
    });
    return { id, ...data };
  }
);

const multiBuildSettingsSlice = createSlice({
  name: "multiBuildSettings",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMultiBuildSettings.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchMultiBuildSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        // if we will need to use many multi build settings, we can still use this logic, so for
        // now we just insert first setting
        multiBuildSettingsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchMultiBuildSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateMultiBuildSettings.pending, state => {
        state.status = "loading";
      })
      .addCase(updateMultiBuildSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { id, ...changes } = action.payload;
        multiBuildSettingsAdapter.updateOne(state, { id, changes });
      })
      .addCase(updateMultiBuildSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  }
});

// export const {
// if need to add reducer, import it here
// } = multiBuildSettingsSlice.actions;

export const {
  selectAll: selectAllMultiBuildSettings,
  selectById: selectMultiBuildSettingsById,
  selectIds: selectMultiBuildSettingsIds
} = multiBuildSettingsAdapter.getSelectors(state => state.multiBuildSettings);

export default multiBuildSettingsSlice.reducer;

export const selectMultiBuildIsLoading = state =>
  state.multiBuildSettings.status === "loading";
export const selectActiveMultiBuildSetting = createSelector(
  [selectAllMultiBuildSettings],
  multiBuildSettings => {
    return multiBuildSettings.find(s => s.active);
  }
);

export const selectNotSelectedToMultiBuildSettingsTemplates = createSelector(
  [selectActiveMultiBuildSetting, selectAllProfileTemplates],
  (multiBuildSetting, templates) => {
    return templates.filter(
      t => !Object.keys(multiBuildSetting.settings).includes(t.name)
    );
  }
);

export const selectSelectedToMultiBuildSettings = createSelector(
  [
    selectActiveMultiBuildSetting,
    selectAllMySettings ? selectAllMySettings : () => []
  ],
  (multiBuildSetting, settings) => {
    const usingSettings = settings.filter(
      s =>
        Object.keys(multiBuildSetting.settings).includes(s.name) &&
        multiBuildSetting.slate_id === s.slate_id
    );

    return usingSettings.reduce(
      (res, s) => ({ ...res, [s.id]: multiBuildSetting.settings[s.name] }),
      {}
    );
  }
);
