import { axiosInstanceV1 } from "network/apis";
import { send } from "@giantmachines/redux-websocket";

export const buildLineup = async (params, { dispatch, getState }) => {
  const {
    sports,
    site,
    setBottomBarFilterValue,
    isPreview = false,
    isLateSwap = false,
    lateSwapId,
    isMultiSettingsBuild = false,
    multiSettingsIds = null,
    settingId = null,
    contestExportMode = "all_unique",
  } = params;
  const playersState = getState().players;
  const playerEntities = playersState.entities;
  const slatesState = getState().slates;
  const settingsState = getState().mySettings;
  const allowDuplicates = settingsState.allowDuplicates;
  const slateID = slatesState.slateID;
  const time_beginning = (slatesState.entities[slateID] || {}).time_beginning;
  const excludedIDs = slatesState.excluded[slateID] || [];
  const excludedNames = excludedIDs.map((playerID) => {
    if (playerID.includes(":")) {
      return playerID;
    } else {
      return playerEntities[playerID].name;
    }
  });
  const lockedIDs = slatesState.locked[slateID] || [];

  const lockedNames = [];

  for (let playerID of lockedIDs) {
    if (playerID.includes("|")) {
      lockedNames.push(playerID.replace("|", ":"));
    } else if (playerID.includes(":")) {
      lockedNames.push(playerID);
    } else {
      lockedNames.push(playerEntities[playerID].name);
    }
  }

  const data = {
    site,
    slate: slateID,
    banned: excludedNames.join(","),
    locked: lockedNames.join(","),
    lateSwap: isLateSwap,
    time_beginning,
    isLateSwapRetry: allowDuplicates && isLateSwap,
    lateSwapId,
    multiSettingsIds,
    settingId,
    contest_export_mode: contestExportMode,
  };

  let response;
  if (isPreview) {
    response = await axiosInstanceV1.post(`/${sports}/optimize/`, { ...data });
    return response.data;
  } else if (isMultiSettingsBuild) {
    response = await axiosInstanceV1.post(
      `/${sports}/optimize-multi-settings/`,
      { ...data },
    );
    dispatch(send(response.data, "BUILD"));
    if (setBottomBarFilterValue) {
      setBottomBarFilterValue("lineups");
    }
  } else {
    response = await axiosInstanceV1.post(`/${sports}/optimize-multi/`, {
      ...data,
    });
    dispatch(send(response.data, "BUILD"));
    if (setBottomBarFilterValue) {
      setBottomBarFilterValue("lineups");
    }
  }
};
