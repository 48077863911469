import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import * as LazyComponent from "utils/LazyLoaded";
import PrivateRoute from "utils/PrivateRoute";

const AppRoutes = ({ showCoreBar }) => {
  return (
    <Routes>
      <Route
        element={<LazyComponent.ResponsiveLogin showCoreBar={showCoreBar} />}
        path="/login"
        exact
      />
      <Route
        element={<LazyComponent.ResponsiveSignUp showCoreBar={showCoreBar} />}
        path="/sign-up"
        exact
      />
      <Route
        element={
          <LazyComponent.ResponsiveResetPassword showCoreBar={showCoreBar} />
        }
        path="/reset-password"
        exact
      />
      <Route
        element={
          <LazyComponent.ResponsiveResetPasswordConfirm
            showCoreBar={showCoreBar}
          />
        }
        path="/reset-password-form"
        exact
      />
      <Route
        element={<LazyComponent.ResponsiveHome showCoreBar={showCoreBar} />}
        path="/"
        exact
      />
      <Route
        element={<LazyComponent.ResponsiveBlogPage showCoreBar={showCoreBar} />}
        path="/blog"
        exact
      />
      <Route
        element={<LazyComponent.ResponsiveBlogPost showCoreBar={showCoreBar} />}
        path="/blog/:postId"
        exact
      />
      <Route
        element={
          <PrivateRoute
            component={LazyComponent.ResponsiveSubscribe}
            showCoreBar={showCoreBar}
          />
        }
        path="/subscribe"
        exact
      />
      <Route
        element={
          <PrivateRoute
            component={LazyComponent.ResponsiveAccountPage}
            showCoreBar={showCoreBar}
          />
        }
        path="/account"
        exact
      />
      <Route
        element={<LazyComponent.Main showCoreBar={showCoreBar} />}
        path="/:sports/:site"
        exact
      />
      <Route element={<Navigate to={`/nba/draftkings`} />} path="/nba" exact />
    </Routes>
  );
};

export default AppRoutes;
