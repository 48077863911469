export const updateNHLData = (data, myData, wsData) => {
  const myLines = myData.myLine || {};
  const myPPLines = myData.myPPLine || {};

  return data
    .map(p => {
      let wsLine = wsData?.[p.name]?.line?.line;
      if (wsLine === "") {
        wsLine = "NIS";
      }
      p.line = wsLine || p.line;
      let line = myLines[p.name] || p.line;
      p.pfp = line && line !== "NIS" ? p.pfp : 0;
      let wsPPLine = wsData?.[p.name]?.pp_line?.pp_line;
      if (wsPPLine === "") {
        wsPPLine = "NIS";
      }
      p.pp_line = myPPLines[p.name] || wsPPLine;
      p.my_line = myLines[p.name];
      p.my_pp_line = myPPLines[p.name];
      return p;
    })
    .sort(function(a, b) {
      if (parseFloat(a.pfp) > parseFloat(b.pfp)) {
        return -1;
      }
      if (parseFloat(a.pfp) < parseFloat(b.pfp)) {
        return 1;
      }
      return 0;
    });
};

export const updateMLBData = (data, myData, wsData) => {
  const myBO = myData.myBO || {};
  return data
    .map(p => {
      let batting_order;
      if (Object.keys(myBO).includes(p.name)) {
        batting_order = myBO[p.name];
      } else if (
        wsData?.[p.name.split(" : ")[0]]?.batting_order?.batting_order !==
        undefined
      ) {
        batting_order =
          wsData?.[p.name.split(" : ")[0]]?.batting_order?.batting_order;
      } else {
        batting_order = p.batting_order;
      }
      if (!batting_order) {
        batting_order = "NIS";
      }
      p.batting_order = batting_order;
      p.pfp = batting_order && batting_order !== "NIS" ? p.pfp : 0;
      p.my_bo = myBO[p.name];
      return p;
    })
    .sort(function(a, b) {
      if (parseFloat(a.pfp) > parseFloat(b.pfp)) {
        return -1;
      }
      if (parseFloat(a.pfp) < parseFloat(b.pfp)) {
        return 1;
      }
      return 0;
    });
};

export const updateSoccerData = (data, wsData) => {
  return data
    .map(p => {
      if (
        wsData?.[p.name]?.starting_position?.starting_position !== undefined
      ) {
        p.starting_position =
          wsData?.[p.name]?.starting_position?.starting_position;
      }
      p.pfp = p?.starting_position ? p.pfp : 0;
      return p;
    })
    .sort(function(a, b) {
      if (parseFloat(a.pfp) > parseFloat(b.pfp)) {
        return -1;
      }
      if (parseFloat(a.pfp) < parseFloat(b.pfp)) {
        return 1;
      }
      return 0;
    });
};
