import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import reduxWebsocket from "@giantmachines/redux-websocket";

import userReducer from "store/userSlice";
import slatesReducer from "store/slatesSlice";
import playersReducer from "store/playersSlice";
import profileTemplatesReducer from "store/profileTemplatesSlice";
import mySettingsReducer from "store/mySettingsSlice";
import multiBuildSettingsReducer from "store/MultiBuildSettingsSlice";
import blogPostsReducer from "store/blogPostsSlice";

const slatesPersistConfig = {
  key: "slates",
  version: 1,
  storage: storage,
  whitelist: ["excluded", "locked", "myData", "settings"],
};

const rootReducer = combineReducers({
  user: userReducer || (() => null),
  slates: persistReducer(slatesPersistConfig, slatesReducer) || (() => null),
  players: playersReducer || (() => null),
  profileTemplates: profileTemplatesReducer || (() => null),
  mySettings: mySettingsReducer || (() => null),
  multiBuildSettings: multiBuildSettingsReducer || (() => null),
  blogPosts: blogPostsReducer || (() => null),
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const buildReduxWebsocketMiddleware = reduxWebsocket({
  prefix: "BUILD",
  reconnectOnError: true,
  reconnectOnClose: true,
});

const processorReduxWebsocketMiddleware = reduxWebsocket({
  prefix: "PROCESSOR",
  reconnectInterval: 1000,
  reconnectOnClose: true,
  reconnectOnError: true,
});

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "BUILD::OPEN",
          "BUILD::CLOSED",
          "BUILD::ERROR",
          "BUILD::MESSAGE",
          "PROCESSOR::OPEN",
          "PROCESSOR::CLOSED",
          "PROCESSOR::ERROR",
          "PROCESSOR::MESSAGE",
          "slates/updateProfileTemplate/fulfilled",
        ],
        ignoredActionPaths: [
          "meta.arg",
          "meta.timestamp",
          "*.callbackFunc",
          "payload.callbackFunc",
          "payload.savedCallbackFunc",
        ],
      },
    })
      .concat(buildReduxWebsocketMiddleware)
      .concat(processorReduxWebsocketMiddleware),
});
