import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { axiosInstanceV2 } from "network/apis";
import {
  activeMySettingFunc,
  createMySettingFunc,
  activateOrCreateMySettingFunc,
  deleteMySettingFunc,
  updateMySettingFunc,
} from "store/mySettingsFunctions";
import { debounce } from "lodash";

const mySettingsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

export const initialState = mySettingsAdapter.getInitialState({
  activeMySettingStatus: "idle",
  createMySettingStatus: "idle",
  activateOrCreateMySettingStatus: "idle",
  deleteMySettingStatus: "idle",
  fetchMySettingsStatus: "idle",
  updateMySettingStatus: "idle",
  allowDuplicates: true,
  contest_export_mode: "highest_projected_per_contest",
  error: null,
});

export const fetchMySettings = createAsyncThunk(
  "mySettings/fetchMySettings",
  async ({ slateID, slateVariety }) => {
    const response = await axiosInstanceV2.get(`/core/my_setting/`, {
      params: { slate_id: slateID, slate_variety: slateVariety },
    });
    return { mySettings: response.data };
  },
);

export const activeMySetting = createAsyncThunk(
  "mySettings/activeMySetting",
  activeMySettingFunc,
);

export const createMySetting = createAsyncThunk(
  "mySettings/createMySetting",
  createMySettingFunc,
);

export const activateOrCreateMySetting = createAsyncThunk(
  "mySettings/activateOrCreateMySetting",
  activateOrCreateMySettingFunc,
);

export const deleteMySetting = createAsyncThunk(
  "mySettings/deleteMySetting",
  deleteMySettingFunc,
);

const debouncedUpdateMySettingFunc = debounce(updateMySettingFunc, 1000);

export const updateMySetting = createAsyncThunk(
  "mySettings/updateMySetting",
  debouncedUpdateMySettingFunc,
);

const mySettingsSlice = createSlice({
  name: "mySettings",
  initialState,
  reducers: {
    setAllowDuplicates(state, action) {
      state.allowDuplicates = action.payload;
    },
    setContextExportMode(state, action) {
      state.contest_export_mode = action.payload;
    },
    resetMySettings(state) {
      state.fetchMySettingsStatus = "idle";
      state.error = null;
      mySettingsAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMySettings.pending, (state) => {
        state.fetchMySettingsStatus = "loading";
      })
      .addCase(fetchMySettings.fulfilled, (state, action) => {
        state.fetchMySettingsStatus = "succeeded";
        const { mySettings } = action.payload;
        mySettingsAdapter.upsertMany(state, mySettings);
      })
      .addCase(fetchMySettings.rejected, (state, action) => {
        state.fetchMySettingsStatus = "failed";
        state.error = action.payload;
      })
      .addCase(activeMySetting.pending, (state) => {
        state.activeMySettingStatus = "loading";
      })
      .addCase(activeMySetting.fulfilled, (state, action) => {
        state.activeMySettingStatus = "succeeded";
        const { mySettingID } = action.payload;
        const parameters = state.ids
          .filter((id) => id !== mySettingID)
          .map((id) => ({ id, changes: { is_active: false } }));
        mySettingsAdapter.updateMany(state, parameters);
        mySettingsAdapter.updateOne(state, {
          id: mySettingID,
          changes: { is_active: true },
        });
      })
      .addCase(activeMySetting.rejected, (state, action) => {
        state.activeMySettingStatus = "failed";
        state.error = action.payload;
      })
      .addCase(createMySetting.pending, (state) => {
        state.createMySettingStatus = "loading";
      })
      .addCase(createMySetting.fulfilled, (state, action) => {
        state.createMySettingStatus = "succeeded";
        const { mySetting } = action.payload;

        if (mySetting.id) {
          mySettingsAdapter.upsertOne(state, mySetting);
          if (mySetting.is_active) {
            mySettingsAdapter.updateMany(
              state,
              state.ids
                .filter((id) => id !== mySetting.id)
                .map((id) => ({ id, changes: { is_active: false } })),
            );
          }
        }
      })
      .addCase(createMySetting.rejected, (state, action) => {
        state.createMySettingStatus = "failed";
        state.error = action.payload;
      })
      .addCase(activateOrCreateMySetting.pending, (state) => {
        state.createMySettingStatus = "loading";
      })
      .addCase(activateOrCreateMySetting.fulfilled, (state, action) => {
        state.createMySettingStatus = "succeeded";
        const { mySetting } = action.payload;
        if (mySetting.id) {
          mySettingsAdapter.updateMany(
            state,
            state.ids
              .filter((id) => id !== mySetting.id)
              .map((id) => ({ id, changes: { is_active: false } })),
          );
          mySettingsAdapter.upsertOne(state, mySetting);
        }
      })
      .addCase(activateOrCreateMySetting.rejected, (state, action) => {
        state.createMySettingStatus = "failed";
        state.error = action.payload;
      })
      .addCase(deleteMySetting.pending, (state) => {
        state.deleteMySettingStatus = "loading";
      })
      .addCase(deleteMySetting.fulfilled, (state, action) => {
        state.deleteMySettingStatus = "succeeded";
        const { mySettingID } = action.payload;
        if (mySettingID) {
          mySettingsAdapter.removeOne(state, mySettingID);
        }
      })
      .addCase(deleteMySetting.rejected, (state, action) => {
        state.deleteMySettingStatus = "failed";
        state.error = action.payload;
      })
      .addCase(updateMySetting.pending, (state, action) => {
        state.updateMySettingStatus = "loading";

        // Update state immediately in pending state
        let { callbackFunc, mySettingID: id, ...changes } = action.meta.arg;
        changes.is_active = true;
        mySettingsAdapter.updateOne(state, { id, changes });
      })
      .addCase(updateMySetting.fulfilled, (state, action) => {
        state.updateMySettingStatus = "succeeded";
      })
      .addCase(updateMySetting.rejected, (state, action) => {
        state.updateMySettingStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetMySettings, setAllowDuplicates, setContextExportMode } =
  mySettingsSlice.actions;

export default mySettingsSlice.reducer;

export const selectActiveMySettingStatus = (state) =>
  state.mySettings.activeMySettingStatus;
export const selectCreateMySettingStatus = (state) =>
  state.mySettings.createMySettingStatus;
export const selectDeleteMySettingStatus = (state) =>
  state.mySettings.deleteMySettingStatus;
export const selectFetchMySettingsStatus = (state) =>
  state.mySettings.fetchMySettingsStatus;
export const selectUpdateMySettingStatus = (state) =>
  state.mySettings.updateMySettingStatus;

export const {
  selectAll: selectAllMySettings,
  selectById: selectMySettingById,
  selectIds: selectMySettingIds,
} = mySettingsAdapter.getSelectors((state) => state.mySettings);

export const selectActiveMySettingFunc = (mySettings) =>
  mySettings.find((s) => s.is_active) || {};

export const selectActiveMySetting = createSelector(
  [selectAllMySettings],
  (mySettings) => selectActiveMySettingFunc(mySettings),
);

export const selectAllowDuplicates = (state) =>
  state.mySettings.allowDuplicates;

export const selectContestExportMode = (state) =>
  state.mySettings.contest_export_mode;

export const selectMySettingIsLoading = createSelector(
  [
    selectActiveMySettingStatus,
    selectDeleteMySettingStatus,
    selectFetchMySettingsStatus,
    selectUpdateMySettingStatus,
  ],
  (
    activeMySettingStatus,
    deleteMySettingsStatus,
    fetchMySettingsStatus,
    updateMySettingStatus,
  ) => {
    return [
      activeMySettingStatus,
      deleteMySettingsStatus,
      fetchMySettingsStatus,
      updateMySettingStatus,
    ].some((s) => s === "loading");
  },
);

export const selectMySettingOptimizeBy = createSelector(
  [selectActiveMySetting],
  (mySetting) => mySetting.optimize_by,
);
