import { add, sub } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import * as Yup from "yup";

const currentDate = () => {
  let timeZone;
  try {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.error(e);
    timeZone = "America/Vancouver";
  }
  return formatToTimeZone(new Date(), "YYYY-MM-DD", {
    timeZone,
  });
};

export function findDefaultDate(disabledDates) {
  // fixme need to check this function
  let defaultDate = currentDate();

  let delta = 1;
  while (delta < 7) {
    if (disabledDates.includes(defaultDate)) {
      defaultDate = add(new Date(defaultDate), { days: 1 }).toISOString();
    } else {
      return defaultDate;
    }
    delta++;
  }

  defaultDate = currentDate();
  while (delta > 0) {
    if (disabledDates.includes(defaultDate)) {
      defaultDate = sub(new Date(defaultDate), { days: 1 }).toISOString();
    } else {
      return defaultDate;
    }
    delta--;
  }

  defaultDate = currentDate();
  return defaultDate;
}

export function findDefaultSlate(slates) {
  let slateID = null;
  if (slates && slates.length > 0) {
    let slate = slates.find(
      (s) =>
        s.name.toLowerCase().indexOf("main") !== -1 ||
        s.name.indexOf("Classic:") !== -1,
    );
    if (!slate) {
      slate = slates.find((s) => s.variety === "classic");
    }
    if (!slate) {
      slate = slates[0];
    }
    slateID = slate.id;
  }
  return slateID;
}

export function getRangeIndicator(minValue, maxValue) {
  const indicators = [];
  let step = 1;
  if (maxValue > 10 && maxValue <= 100) {
    step = 10;
  } else if (maxValue > 100 && maxValue <= 1000) {
    step = 100;
  } else if (maxValue > 1000 && maxValue <= 10000) {
    step = 1000;
  } else if (maxValue > 10000) {
    step = 5000;
  }
  let item = minValue;
  while (item <= maxValue) {
    indicators.push(item);
    item += step;
  }
  return indicators;
}

export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = "ValidationError";
  }
}

export function getAbbrName(name) {
  const [namePart, posPart] = name.split(" : ");
  let abbrName = namePart;
  if (namePart.includes(" ")) {
    abbrName = `${namePart.split(" ")[0][0]}. ${namePart
      .split(" ")
      .slice(1)
      .join(" ")
      .slice(0, 8)}`;
  }
  if (posPart) {
    return [abbrName, posPart].join(" : ");
  }
  return abbrName;
}

export function getModelOptions(sports) {
  let modelSettingsOptions = [];
  let initialSelectedModelOption;

  if (sports === "nba") {
    modelSettingsOptions = [
      "Avg points per minute in last games",
      "Avg points in last games",
      "Implied Team Total",
      "Min points per minute higher N in M last games",
      "Ratio between projected and actual points in last games",
    ];
    initialSelectedModelOption = "Avg points per minute in last games";
  }

  if (sports === "cbb") {
    modelSettingsOptions = [
      "Avg points per minute in last games",
      "Avg points in last games",
      "Implied Team Total",
      "Min points per minute higher N in M last games",
      "Ratio between projected and actual points in last games",
    ];
    initialSelectedModelOption = "Avg points per minute in last games";
  }

  if (sports === "nhl") {
    modelSettingsOptions = [
      "Avg points per minute in last games",
      "Avg points in last games",
      "Implied Team Total",
      "Min points per minute higher N in M last games",
      "Ratio between projected and actual points in last games",
      "Weight of projected Hits",
      "Weight of projected Assists",
      "Weight of projected Goals",
      "Weight of projected Shots",
    ];
    initialSelectedModelOption = "Avg points per minute in last games";
  }

  if (sports === "mlb") {
    modelSettingsOptions = [
      "Avg points in last games",
      "Implied Team Total",
      "Min points higher N in M last games",
      "Ratio between projected and actual points in last games",
    ];
    initialSelectedModelOption = "Avg points in last games";
  }

  if (sports === "nfl") {
    modelSettingsOptions = [
      "Avg points in last games",
      "Implied Team Total",
      "Min points higher N in M last games",
      "Ratio between projected and actual points in last games",
    ];
    initialSelectedModelOption = "Avg points in last games";
  }

  if (sports === "golf") {
    modelSettingsOptions = ["Cut in last games"];
    initialSelectedModelOption = "Cut in last games";
  }
  return { modelSettingsOptions, initialSelectedModelOption };
}

export function getAddedModelSettings(modelSettingsValue, sports) {
  let addedModelSettings = [];
  if (parseInt(modelSettingsValue.pointsPerMinuteWeight)) {
    addedModelSettings.push(
      `Avg points per minute weight is ${modelSettingsValue.pointsPerMinuteWeight} for last ` +
        `${modelSettingsValue.pointsPerMinuteGames || 10} games`,
    );
  }
  if (parseInt(modelSettingsValue.lastGamesPointsWeight)) {
    addedModelSettings.push(
      `Avg points weight is ${modelSettingsValue.lastGamesPointsWeight} for last ` +
        `${modelSettingsValue.pointsGames || 10}`,
    );
  }
  if (parseInt(modelSettingsValue.vegasWeight)) {
    addedModelSettings.push(
      `Implied team total weight is ${modelSettingsValue.vegasWeight}`,
    );
  }
  if (parseInt(modelSettingsValue.pfpActualWeight)) {
    addedModelSettings.push(
      `Weight for ratio between projected and actual points in last games is ` +
        `${modelSettingsValue.pfpActualWeight}`,
    );
  }
  if (
    parseInt(modelSettingsValue.minPfpPerMinuteInLastGames) &&
    modelSettingsValue.minPfpPerMinuteGames &&
    parseInt(modelSettingsValue.minPfpPerMinuteGames[0]) > 0
  ) {
    addedModelSettings.push(
      `Players must have at least ${modelSettingsValue.minPfpPerMinuteInLastGames} fpts per minute ` +
        `in ${modelSettingsValue.minPfpPerMinuteGames[0]} of ${modelSettingsValue.minPfpPerMinuteGames[1]} games ` +
        `and this rule can be skipped for ${
          modelSettingsValue.minPfpPerMinuteInLastGamesSkipCount || 0
        } players`,
    );
  }

  if (sports === "nhl") {
    for (const position of ["C", "W", "D"]) {
      for (const param of ["hits", "assists", "goals", "shots"]) {
        if (parseInt(modelSettingsValue[`projected_${param}|${position}`])) {
          addedModelSettings.push(
            `Weight for projected ${param} on ${position} is ` +
              `${modelSettingsValue[`projected_${param}|${position}`]}`,
          );
        }
      }
    }
  }

  if (sports === "nfl") {
    for (const position of ["QB", "WR/TE/K", "RB", "DEF"]) {
      if (parseInt(modelSettingsValue[`lastGamesPointsWeight${position}`])) {
        addedModelSettings.push(
          `Weight for actual points in ${
            modelSettingsValue[`pointsGames${position}`] || 10
          } last games on ${position} ` +
            `position is ${
              modelSettingsValue[`lastGamesPointsWeight${position}`]
            }`,
        );
      }
      if (parseInt(modelSettingsValue[`vegasWeight${position}`])) {
        addedModelSettings.push(
          `Weight for implied team total on ${position} position ` +
            `is ${modelSettingsValue[`vegasWeight${position}`]}`,
        );
      }
      if (parseInt(modelSettingsValue[`pfpActualWeight${position}`])) {
        addedModelSettings.push(
          `Weight for ratio between projected and actual points in last games ${position} position ` +
            `is ${modelSettingsValue[`pfpActualWeight${position}`]}`,
        );
      }
      if (
        parseInt(
          modelSettingsValue[`actualInLastGamesByPosition${position}`],
        ) &&
        modelSettingsValue[`minActualGames${position}`] &&
        parseInt(modelSettingsValue[`minActualGames${position}`][0]) > 0
      ) {
        addedModelSettings.push(
          `Players on ${position} position must have at least ` +
            `${
              modelSettingsValue[`actualInLastGamesByPosition${position}`]
            } fpts ` +
            `in ${modelSettingsValue[`minActualGames${position}`][0]} of ` +
            `${modelSettingsValue[`minActualGames${position}`][1]} games ` +
            `and this rule can be skipped for ${
              modelSettingsValue[`minActualInLastGamesSkipCount${position}`] ||
              0
            }`,
        );
      }
    }
  }

  if (sports === "mlb") {
    for (const position of ["Pitchers", "Batters"]) {
      if (parseInt(modelSettingsValue[`lastGamesPointsWeight${position}`])) {
        addedModelSettings.push(
          `Weight for actual points in ${
            modelSettingsValue[`pointsGames${position}`] || 10
          } last games for ${position} ` +
            `is ${modelSettingsValue[`lastGamesPointsWeight${position}`]}`,
        );
      }
      if (parseInt(modelSettingsValue[`vegasWeight${position}`])) {
        addedModelSettings.push(
          `Weight for implied team total for ${position} is ${
            modelSettingsValue[`vegasWeight${position}`]
          }`,
        );
      }
      if (parseInt(modelSettingsValue[`pfpActualWeight${position}`])) {
        addedModelSettings.push(
          `Weight for ratio between projected and actual points in last games for ${position} ` +
            `is ${modelSettingsValue[`pfpActualWeight${position}`]}`,
        );
      }
      if (
        parseInt(
          modelSettingsValue[`actualInLastGamesByPosition${position}`],
        ) &&
        modelSettingsValue[`minActualGames${position}`] &&
        parseInt(modelSettingsValue[`minActualGames${position}`][0]) > 0
      ) {
        addedModelSettings.push(
          `${position} must have at least ` +
            `${
              modelSettingsValue[`actualInLastGamesByPosition${position}`]
            } fpts ` +
            `in ${modelSettingsValue[`minActualGames${position}`][0]} of ` +
            `${modelSettingsValue[`minActualGames${position}`][1]} games ` +
            `and this rule can be skipped for ${
              modelSettingsValue[`minActualInLastGamesSkipCount${position}`] ||
              0
            }`,
        );
      }
    }
  }

  return addedModelSettings;
}

export function getSettingsFormValidator(
  specialPositionsInitial,
  sports,
  isProfile = false,
  isStaff = false,
) {
  let maxLineupsCount = 150;
  if (isStaff) {
    maxLineupsCount = 1500;
  }

  let teamStackCheck = {
    stackSize: Yup.number()
      .typeError("please set a valid stack size")
      .positive()
      .required("please set a valid stack size")
      .min(0, "min value of stack size is 1")
      .max(6, "max value of stack size is 6"),
    positions: Yup.array().min(1, "please select at least 1 position"),
  };
  if (!isProfile) {
    teamStackCheck = {
      ...teamStackCheck,
      uniques: Yup.number()
        .typeError("uniques value must be a number")
        .positive()
        .required("please set uniques for all stacks")
        .min(0, "min value of uniques is 1")
        .max(100, "max value of uniques is 5"),
      teams: Yup.object().test(
        "teams",
        "please select at least 1 team",
        (val) => Object.keys(val).length > 0,
      ),
    };
  }
  const validationSchema = {
    lineup_count: Yup.number()
      .required("lineup count is a required field")
      .typeError("lineup count is a required field")
      .positive()
      .integer()
      .min(1, "you can build 1 to 150 lineups")
      .max(maxLineupsCount, `you can build 1 to ${maxLineupsCount} lineups`),
    min_projection: Yup.number()
      .required("min projection is a required field")
      .typeError("min projection is a required field")
      .positive()
      .min(0, "min projection must greater than 0")
      .max(100, "min projection must less than 100"),
    name: Yup.string().required().min(3).max(32),
    optimize_by: Yup.string().required().min(2).max(16),
    randomness: Yup.number()
      .required("randomness is a required field")
      .typeError("randomness is a required field")
      .positive()
      .min(0, "randomness must greater than 0")
      .max(30, "randomness must less than 30"),
    salary_range: Yup.array().required(),
    unique: Yup.number()
      .required("unique is a required field")
      .typeError("unique is a required field")
      .positive()
      .min(1, "unique must greater than 1")
      .max(10, "unique must less than 10"),
    max_total_ownerships: Yup.number()
      .required("max total ownerships is a required field")
      .typeError("max total ownerships is a required field")
      .positive()
      .min(1, "min total ownerships must be 1")
      .max(1000, " max total ownerships must be less than 1000"),
    max_difference_exposure: Yup.number()
      .required("max difference exposure is a required field")
      .typeError("max difference exposure is a required field")
      .positive()
      .min(0, "max difference exposure must greater than 0")
      .max(100, "max difference exposure must less than 100"),
    max_same_teams_stacks: Yup.number()
      .required("max same teams stacks is a required field")
      .typeError("max same teams stacks is a required field")
      .positive()
      .min(0, "max same teams stacks must greater than 0")
      .max(100, "max same teams stacks must less than 100"),
    additional_settings: Yup.object().shape({
      lines_allowed: Yup.array().min(
        1,
        "please select at least one line for players",
      ),
    }),
    use_max_difference_exposure: Yup.bool(),
    smart_random: Yup.bool(),

    team_stacks: Yup.array().nullable().of(Yup.object().shape(teamStackCheck)),
  };

  const onlySettingsKeys = ["max_same_teams_stacks"];

  if (isProfile) {
    for (let key of onlySettingsKeys) {
      delete validationSchema[key];
    }
  }

  if (specialPositionsInitial) {
    validationSchema["special_positions"] = Yup.object().shape(
      Object.keys(specialPositionsInitial || {}).reduce(
        (o, pos) => ({
          ...o,
          [pos]: Yup.array().min(
            1,
            `please select at least on real position for ${pos}`,
          ),
        }),
        {},
      ),
    );
  }

  if (
    ["nhl", "mlb", "nfl", "soccer", "cs", "lol", "mma", "tennis"].includes(
      sports,
    )
  ) {
    validationSchema["max_opponent"] = Yup.number()
      .positive()
      .required("max opponent is required field")
      .min(0, "max opponent must greater than 0")
      .max(10, "max opponent must less than 10");
  }
  return validationSchema;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function roundToTwoDecimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function getGameKey(game) {
  if (!game.team_away || !game.team_home) {
    return "";
  }
  return `${game.team_away}@${game.team_home}`;
}

export function copyLineupsDataRows(lineups, site) {
  return lineups.map((lineup) =>
    lineup.players.map((player) => {
      let value = `"${player.site_id}:${player.name}"`;
      if (site === "draftkings") {
        value = `${player.name} (${player.site_id})`;
        if (player.pos === "CPT") {
          value = `${player.name} (${player.cpt_site_id})`;
        }
      }
      if (site === "fanteam") {
        value = player.site_id;
      }
      if (site === "yahoo") {
        value = `${player.site_id} - ${player.name}`;
      }
      return value;
    }),
  );
}
