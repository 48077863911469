import { axiosInstanceV2 } from "network/apis";

export const updateSortedColumnFunc = async params => {
  const { sport, datum, savedCallbackFunc } = params;

  await axiosInstanceV2.post("/core/sorted_column/", { sport, datum });

  savedCallbackFunc();
  return params;
};
