import { ValidationError } from "utils/Shared";
import numeral from "numeral";

import { message } from "antd";
import { getInitialSpecialPositions } from "store/settingsInitialValue";

function calculateFinalPositionRules(positionRules, flexPositions) {
  // Creating a deep copy of the positionRules to avoid modifying the original object
  const finalRules = JSON.parse(JSON.stringify(positionRules));

  // Iterate through each flex type (like FLEX and SUPERFLEX)
  for (let flexType in flexPositions) {
    // Check if the flexType is in the positionRules
    if (finalRules[flexType]) {
      const positions = flexPositions[flexType];
      const [flexMin, flexMax] = finalRules[flexType];

      // Iterate through each position within a flex type
      positions.forEach(position => {
        // Increase the maximum of the position by the flex maximum
        if (finalRules[position]) {
          finalRules[position][1] += flexMax;
        } else {
          // If the position is not in positionRules, add it with the flex values
          finalRules[position] = [flexMin, flexMax];
        }
      });
    }
  }

  return finalRules;
}

const checkPositionRule = (
  positionsRule,
  lockedPlayers,
  player,
  specialPositionsInitial
) => {
  const finalPositionsRule = calculateFinalPositionRules(
    positionsRule,
    specialPositionsInitial
  );

  const positionsAfterLock = lockedPlayers.map(p => p.position);
  const single = positionsAfterLock.filter(pos => !pos.includes("/"));
  const multiple = positionsAfterLock.filter(pos => pos.includes("/"));
  const statistics = single.reduce((acc, pos) => {
    if (!acc[pos]) {
      acc[pos] = 1;
    } else {
      acc[pos] += 1;
    }
    return acc;
  }, {});

  multiple.forEach(multiPos => {
    let [pos1, pos2] = multiPos.split("/");
    if (!Object.keys(finalPositionsRule).includes(pos1)) {
      pos1 = Object.keys(finalPositionsRule).filter(pos =>
        pos.split("/").includes(pos1)
      );
    }
    if (!Object.keys(finalPositionsRule).includes(pos2)) {
      pos2 = Object.keys(finalPositionsRule).filter(pos =>
        pos.split("/").includes(pos2)
      );
    }
    const pos1Count = statistics[pos1];
    const pos1Placeholder = finalPositionsRule[pos1][1] - pos1Count;
    const pos2Count = statistics[pos2];
    const pos2Placeholder = finalPositionsRule[pos2][1] - pos2Count;
    if (pos1Placeholder > pos2Placeholder) {
      if (!statistics[pos1]) {
        statistics[pos1] = 1;
      } else {
        statistics[pos1] += 1;
      }
    } else {
      if (!statistics[pos2]) {
        statistics[pos2] = 1;
      } else {
        statistics[pos2] += 1;
      }
    }
  });

  if (!player.position.includes("/")) {
    let thePosition = player.position;
    // handle MLB fanduel case, here we have "C/1B" position
    if (!Object.keys(finalPositionsRule).includes(thePosition)) {
      thePosition = Object.keys(finalPositionsRule).filter(pos =>
        player.position.split("/").some(p => pos.split("/").includes(p))
      );
    }
    if (statistics[thePosition] >= finalPositionsRule[thePosition][1]) {
      throw new ValidationError(
        `You can't lock more than ${
          finalPositionsRule[thePosition][1]
        } ${thePosition} players`
      );
    }
  }

  if (player.position.includes("/")) {
    let [pos1, pos2] = player.position.split("/");
    if (!Object.keys(finalPositionsRule).includes(pos1)) {
      pos1 = Object.keys(finalPositionsRule).filter(pos =>
        pos.split("/").includes(pos1)
      );
    }
    if (!Object.keys(finalPositionsRule).includes(pos2)) {
      pos2 = Object.keys(finalPositionsRule).filter(pos =>
        pos.split("/").includes(pos2)
      );
    }
    if (
      statistics[pos1] >= finalPositionsRule[pos1][1] &&
      statistics[pos2] >= finalPositionsRule[pos2][1]
    ) {
      throw new ValidationError(
        `You can't lock more than ${finalPositionsRule[pos1][1]}/${
          finalPositionsRule[pos2][1]
        } ${player.position} players`
      );
    }
  }
};

const canBeLocked = params => {
  const { slate, players, player, maxSalary, position } = params;
  const specialPositionsInitial = getInitialSpecialPositions(
    slate.sports,
    slate.site,
    slate.variety
  );

  let { locked } = params;

  const totalPlayersRule = slate.total_players;

  locked = locked.filter(lockedID => lockedID.split("|") !== player.id);

  if (locked.length >= totalPlayersRule) {
    throw new ValidationError(
      `You can't lock more than ${totalPlayersRule} players`
    );
  }

  const lockedPlayers = locked.map(lockedID => players[lockedID.split("|")[0]]);

  const lockedSalary = lockedPlayers.reduce((total, p) => total + p.salary, 0);
  if (lockedSalary + player.salary > maxSalary) {
    throw new ValidationError(
      `Locked players' total salary can't greater than ${numeral(
        maxSalary
      ).format("$0,0")}`
    );
  }

  const positionsRule = slate.positions;
  checkPositionRule(
    positionsRule,
    lockedPlayers,
    player,
    specialPositionsInitial
  );

  const theName = player.name.split(" : ")[0];
  if (!position && lockedPlayers.find(p => p.name.includes(theName))) {
    throw new ValidationError("You can't lock a player on 2 positions");
  }

  return true;
};

export const lockPlayer = (params, { dispatch, getState }) => {
  const { playerID, position = undefined, showAntdMessage = false } = params;
  const playerState = getState().players;
  const players = playerState.entities;
  const player = playerState.entities[playerID];

  const slateState = getState().slates;
  const slateID = slateState.slateID;
  const slate = slateState.entities[slateID];

  const mySettingState = getState().mySettings;
  const settings = Object.values(mySettingState.entities).find(
    s => s.slate_id === slateID && s.is_active
  );
  let maxSalary = slate.salary;
  if (settings && settings.salary_range) {
    maxSalary = settings.salary_range[1];
  }

  setTimeout(() => {
    dispatch({ type: "slates/resetLockErrorAndStatus" });
  }, 1500);

  const locked = slateState.locked[slateID] || [];
  try {
    if (canBeLocked({ slate, players, player, locked, maxSalary, position })) {
      if (position && position !== "any") {
        return `${playerID}|${position}`;
      }
      return playerID;
    }
  } catch (err) {
    if (showAntdMessage) {
      message.warning(err.message);
    }
    throw err;
  }
};

export const unlockPlayer = (params, { dispatch }) => {
  const { playerID } = params;

  setTimeout(() => {
    dispatch({ type: "slates/resetLockErrorAndStatus" });
  }, 1500);

  return playerID;
};
