import React, { useEffect, useState } from "react";

import { Box, Grommet } from "grommet";

import * as LazyComponent from "utils/LazyLoaded";
import AppRoutes from "routes/AppRoutes";
import "./App.css";
import { useLocation } from "react-router-dom";
import { toTitleCase } from "utils/Shared";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { selectUseDarkTheme } from "store/userSlice";
import mobileLightTheme from "./themes/grommet/mobileLightTheme";
import mobileDarkTheme from "./themes/grommet/mobileDarkTheme";

function App() {
  const location = useLocation();
  const [showCoreBar, setShowCoreBar] = useState(false);
  const ANALYTIC_GOOGLE_ID = process.env.ANALYTIC_GOOGLE_ID;

  useEffect(() => {
    ReactGA.initialize(ANALYTIC_GOOGLE_ID);
  }, [ANALYTIC_GOOGLE_ID]);

  useEffect(() => {
    const titleWords = location.pathname.split("/").filter((p) => p);
    if (titleWords.length > 1) {
      const sport = titleWords[0].toUpperCase();
      const site = toTitleCase(titleWords[1]);
      let title = `${sport} ${site} - DFS Crunch`;
      if (document && document.title !== title) {
        document.title = title;
      }
    }

    const currentPath = location.pathname + location.search;
    ReactGA.pageview(currentPath);
  }, [location]);

  const useDarkTheme = useSelector(selectUseDarkTheme);
  const grommetTheme = useDarkTheme ? mobileDarkTheme : mobileLightTheme;

  return (
    <Grommet theme={grommetTheme} full>
      <Box fill key="outer">
        <Box flex={false} key="box1">
          <LazyComponent.NavBar
            showCoreBar={showCoreBar}
            setShowCoreBar={setShowCoreBar}
          />
        </Box>
        <Box overflow="auto" key="box2" style={{ minHeight: "100%" }}>
          {<AppRoutes showCoreBar={showCoreBar} />}
        </Box>
      </Box>
    </Grommet>
  );
}

export default App;
