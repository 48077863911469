import React from "react";
import { Navigate } from "react-router-dom";

import Auth from "utils/Auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { showCoreBar } = rest;
  if (Auth.isAuth()) {
    return <Component showCoreBar={showCoreBar} {...rest} />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
