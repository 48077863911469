import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import "./index.css";
import App from "./App";
import store from "./store";
import history from "./routes/History";

window.React = React;

let persistor = persistStore(store);

let container = null;

document.addEventListener("DOMContentLoaded", function (event) {
  if (!container) {
    container = document.getElementById("root");
    const root = ReactDOM.createRoot(container);
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <App />
            </Router>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
    );
  }
});

if (module.hot) {
  module.hot.accept();
}
